export interface PartnerObject {
  slug: string;
  firstName: string;
  lastName: string;
  finishedConversationCount: number;
  meFirst: boolean;
  timeZone: string;
  avatarUrl: string;
}

export interface Organization {
  name: string;
  code: string;
  defaultIndustry: string;
  seatLimit: number;
  isDemo: boolean;
}

export interface PresenceTrackerObject {
  msgType: string;
  msg: string;
}

export interface JoinConversationObject {
  pcId: string;
}

export interface JoinConversationResultObject {
  member: PartnerObject;
  partner: PartnerObject;
}

export interface Conversation {
  matchedOn: string;
  completeBy: string;
  setTitle: string;
  conversationTitle: string;
  conversationGoals: string;
  conversationCount: number;
  conversationOrder: number;
  completion: number;
  scheduledDateTime: string;
  scheduledTimeZone: string;
}

export interface QuestionsEntity {
  questionId: string;
  label: string;
  questionType: string;
  answerType: string;
  title: string;
  shortTitle: string;
  description: string;
}

export interface AgendaItemProps {
  id: string;
  label: string;
}

export interface TokenEntity {
  nameid: string;
  name: string;
  email: string;
  display_name: string;
  memberFirstName: string;
  memberLastName: string;
  organizationId: string;
  organizationCode: string;
  jti: string;
  userdata: string;
  role: string;
  permissions?: string[] | null;
  nbf: number;
  exp: number;
  iat: number;
}

// export interface Commitment {
//   myImperativeAction: ImperativeAction;
//   isActionSelected: boolean;
// }

// export enum ImperativeAction {
//   Relationships ='relationship',
//   Impact = 'impact',
//   Growth = 'growth'
// }

export interface AreasOfCommitment {
  title?: string;

  // active
  areasOfCommitment: Array<AreaOfCommitment>;

  // inactive
  relationship: number;
  impact: number;
  growth: number;
}

export interface AreaOfCommitment {
  title: string;
  id: number;
  subInvestments: [];
}

export interface MemberMatchRequest {
  OptIn: boolean;
  RequestedOn: Date;
  Reason: string;
}

export enum PhaseEmitName {
  canAdvance = "canAdvance",
  advance = "advance"
}

export interface PhaseEmits {
  (event: PhaseEmitName.canAdvance, value: boolean): void;
  (event: PhaseEmitName.advance, value: any): void;
}

export interface PhaseProps {
  content: any;
  ActiveMember: boolean;

}

export enum MatchStatuses {
  Undeclared = 'Undeclared',
  ReadyForMatch = 'Open to Matching',
  NotReadyForMatch = 'Not Open to Matching',
}

