<template>
  <div class="flex h-full flex-col">
    <flow-panel :active-tab="active ? 0 : 1"></flow-panel>
    <TextQuestion v-model="notes" :active="active" :content="questionData"></TextQuestion>

    <div class="mt-4 flex-1 px-4 text-right sm:px-0">
      <base-button v-if="active" class="flex-shrink" :disabled="!canContinue" @click="advance">Next</base-button>
    </div>
    <div class="z-20 px-4 sm:px-0">
      <base-exit-link />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useConductorStore } from '@/stores/conductorStore';

  // props
  interface Props {
    content: any;
  }

  const props = withDefaults(defineProps<Props>(), {
    content: {},
  });

  // emits
  const emits = defineEmits<{
    (event: 'advance', payload: any): void;
  }>();

  const conductorStore = useConductorStore();

  const active = computed(() => conductorStore.isActive);

  const canContinue = computed(() => !!notes.value);

  // get content
  const questionData = ref(null);

  onBeforeMount(async () => {
    questionData.value = props.content;
  });

  // move phase forward
  const notes = ref(null);

  const advance = async () => {
    const model = {
      label: conductorStore.content.label,
      answer: notes.value,
    };
    emits('advance', model);
  };
</script>
