import axios, { AxiosRequestConfig } from 'axios';

const axiosAuthAnymous = axios.create({
  baseURL: import.meta.env.VITE_COREAPI_BASE_URL,
  crossDomain: true,
  headers: {
    'Content-Type': 'application/json'
  },
} as AxiosRequestConfig);

export default axiosAuthAnymous;
