import { App } from 'vue';
import posthog, { PostHogConfig, PostHog } from 'posthog-js';

export default {
  install(app: App) {
    posthog.init(import.meta.env.VITE_POSTHOG_API_KEY, {
      api_host: import.meta.env.VITE_POSTHOG_HOST,
      opt_in_site_apps: true,
      persistence: 'localStorage+cookie',
      autocapture: import.meta.env.PROD,
      loaded: function (posthog) {
        posthog.register_once({
          'App Environment': import.meta.env.VITE_POSTHOG_ENV,
        });
      },
    } as Partial<PostHogConfig>);

    app.config.globalProperties.$posthog = posthog;
  },
};

export { posthog, PostHog };
