<template>
  <!-- <div v-if="editor != null && props.active"> -->
  <div v-if="editor != null">
    <!-- {{ editorTipContent }} -->
    <div class="rounded border border-gray-300" id="triggerDiv" ref="firstTimeTrigger">
      <editor-content :editor="editor" class="max-h-48 min-h-[10rem] w-full overflow-y-auto border-b border-b-gray-100"> </editor-content>
      <!-- TOOLBAR -->
      <div class="flex items-center justify-start gap-x-2 px-4 py-3 text-gray-300">
        <button
          :aria-label="t('textEditor.labelBold')"
          class="p-1 hover:text-gray-700"
          @click="editor.chain().focus().toggleBold().run()"
          :class="{ 'text-gray-500': editor.isActive('bold') }">
          <font-awesome-icon icon="bold" class="h-5 w-5" />
        </button>
        <button
          :aria-label="t('textEditor.labelItalic')"
          class="p-1 hover:text-gray-700"
          @click="editor.chain().focus().toggleItalic().run()"
          :class="{ 'text-gray-500': editor.isActive('italic') }">
          <font-awesome-icon icon="italic" class="h-5 w-5" />
        </button>
        <button
          :aria-label="t('textEditor.labelBulletList')"
          class="p-1 hover:text-gray-700"
          @click="editor.chain().focus().toggleBulletList().run()"
          :class="{ 'text-gray-500': editor.isActive('bulletList') }">
          <font-awesome-icon icon="list" class="h-5 w-5" />
        </button>
        <button
          :aria-label="t('textEditor.labelOrderedList')"
          class="p-1 hover:text-gray-700"
          @click="editor.chain().focus().toggleOrderedList().run()"
          :class="{ 'text-gray-500': editor.isActive('orderedList') }">
          <font-awesome-icon icon="list-ol" class="h-5 w-5" />
        </button>
        <button
          :aria-label="t('textEditor.labelHeading')"
          class="p-1 hover:text-gray-700"
          @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
          :class="{ 'text-gray-500': editor.isActive('heading', { level: 1 }) }">
          <font-awesome-icon icon="heading" class="h-5 w-5" />
        </button>
        <button
          :aria-label="t('textEditor.labelBlockQuote')"
          class="p-1 hover:text-gray-700"
          @click="editor.chain().focus().toggleBlockquote().run()"
          :class="{ 'text-gray-500': editor.isActive('blockquote') }">
          <font-awesome-icon icon="quote-right" class="h-5 w-5" />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { onMounted, onBeforeUnmount, nextTick, Ref, watch, computed } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { Editor, EditorContent } from '@tiptap/vue-3';
  import StarterKit from '@tiptap/starter-kit';
  import Placeholder from '@tiptap/extension-placeholder';
  import { Markdown } from 'tiptap-markdown';
  import CharacterCount from '@tiptap/extension-character-count';
  const { t } = useI18n();

  const editor = ref<Editor>(null);
  const maxCharacterCount = 3000;
  // props
  interface Props {
    modelValue?: string;
    placeholder?: string;
  }

  const props = withDefaults(defineProps<Props>(), {
    modelValue: null,
    placeholder: null,
  });

  // emits
  const emits = defineEmits<{
    (event: 'update:modelValue', value: string): void;
  }>();

  onMounted(async () => {
    editor.value = new Editor({
      extensions: [
        Markdown.configure({}),
        CharacterCount.configure({
          limit: maxCharacterCount,
        }),
        StarterKit.configure({
          history: false,
          code: false,
          strike: false,
          heading: { levels: [1] },
          bulletList: { HTMLAttributes: { class: 'bulletlist' } },
          orderedList: { HTMLAttributes: { class: 'orderedlist' } },
        }),
        Placeholder.configure({
          // placeholder: t('textEditor.textPlaceholder', { partnerName: partnerName.value }),
          placeholder: props.placeholder,
        }),
      ],
      autofocus: true,
      editorProps: {
        attributes: {
          class: 'prose prose-sm m-0 p-4 focus:outline-none max-w-none max-h-48 min-h-[10rem]',
        },
      },
      content: props.modelValue,
      onUpdate: () => {
        // HTML
        emits('update:modelValue', editor.value.storage.markdown.getMarkdown());
      },
    });
    // await loadToolTip();
    // updateToolFirstTips();
  });

  onBeforeUnmount(() => {
    // if (firstTimeTip?.value) {
    //     firstTimeTip.destroy();
    //     firstTimeTip = null;
    // }

    if (editor.value) {
      editor.value.destroy();
      editor.value = null;
    }
  });
</script>

<style lang="scss">
  .ProseMirror p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
    height: 0;
  }

  .bulletlist,
  .orderedlist {
    list-style-type: none;
    li {
      margin: 0;
      p {
        margin: 0;
      }
    }
  }
</style>
