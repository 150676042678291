<template>
  <div class="flex h-full flex-col">
    <div v-if="phaseType === 'post'">
      <div class="flex flex-row">
        <div class="flex-shrink-0">
          <img src="/images/aaron.png" alt="assistant welcome" class="max-w-[8rem]" />
        </div>
        <div class="justify-center text-center text-xl font-bold">{{ $t('match_group_question.subtitle_post') }} </div>
      </div>
    </div>
    <div v-if="phaseType === 'pre'">
      <div class="w-full text-center text-lg font-semibold">{{ $t('match_group_question.subtitle_pre') }} </div>
    </div>
    <div v-for="question in content.questions" :key="question.internalQuestionId" class="mb-1 mt-1">
      <div v-if="question.questionType === 'star_likert'">
        <RatingQuestion class="mb-5 mt-5" :question="question"></RatingQuestion>
      </div>
    </div>
    <div v-if="phaseType === 'pre'" class="mt-4 flex-1 text-right">
      <base-button v-if="active" class="shrink" data-testid="btnNext" @click="advance" :disabled="!isValid"> Next </base-button>
    </div>
    <div v-else>
      <div class="mt-4 flex items-center justify-center">
        <base-button class="text-normal rounded bg-primary-500 px-6 py-2 uppercase text-white hover:bg-primary-700" @click="advance">
          Exit Conversation & Review Notes
        </base-button>
      </div>
    </div>
    <div v-if="phaseType === 'pre'" class="z-20">
      <base-exit-link />
    </div>
  </div>
  <!-- nothing -->
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n';
  import { Question } from '@/components/MatchGroupQuestion/CustomQuestionTypes';

  const { t } = useI18n();
  const active = ref([]);

  interface Props {
    content?: any;
  }

  const emits = defineEmits<{
    (event: 'advance', payload: any): void;
  }>();

  const props = withDefaults(defineProps<Props>(), {
    content: () => [
      {
        questionText: '',
        answerType: '',
        placement: '',
        questionId: '',
        workshopId: '',
        workshopMatchGroupId: '',
        answerValue: null,
        answerDataType: '',
      },
    ],
  });
  const isValid = computed(() => {
    if (!props.content?.questions) {
      throw new Error('Custom Questions: No questions found!');
    }
    return props.content.questions.every((q: Question) => q.answerValue != null && !Number.isNaN(Number(q.answerValue)));
  });

  const phaseType = computed(() => props.content.questions[0].placement.toLowerCase());

  const advance = async () => emits('advance', props.content.questions);
</script>

<style scoped></style>

