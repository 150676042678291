<template>
  <div class="flex flex-col h-full">
    <div class="flex flex-col flex-1 gap-y-4">
      <flow-panel :active-tab="active ? 0 : 1"></flow-panel>

      <!-- HEADER & ACTION -->
      <div class="text-lg font-semibold">{{
        t('actionCheckIn.header', { partnerName: active ? conductorStore.partner.firstName : conductorStore.member.firstName })
      }}</div>
      <div class="flex items-center gap-x-6">
        <div class="flex-shrink-0 hidden lg:block">
          <font-awesome-icon :icon="['fad', 'bullseye-arrow']" class="w-24 h-24 text-primary-600" />
        </div>
        <div class="flex flex-col space-y-3">
          <p class="text-base font-medium leading-relaxed tracking-wide text-green-600">
            {{ activeVoicePlural }} <span class="italic">Imperative</span> from
            {{ $filters.formatDate(content.commitmentDateTime, 'MMMM dd') }}
          </p>
          <p class="text-left text-gray-700 break-normal"> {{ activeVoice }} committed to {{ content.rigName }} - {{ content.rigSubcategoryName }}. </p>
          <p class="text-justify text-gray-700 break-words md:text-left"> {{ activeVoicePlural }} imperative was {{ content.what }}. </p>
          <p class="text-justify break-words md:text-left"> This was an imperative because {{ content.why }}. </p>
        </div>
      </div>

      <!-- INACTIVE MEMBER -->
      <template v-if="!active" >
        <div id="passive-panel" class="flex justify-center pt-6 mt-8 text-sm text-gray-500 border-t border-gray-200">
          <div>
            {{ t('actionCheckIn.inactive', { partnerName }) }}
          </div>
        </div>
      </template>

      <!-- ACTIVE USER -->
      <div v-else class="space-y-4" id="active-panel">
        <!-- COMPLETED? -->
        <RadioGroup v-model="model.performed" @update:modelValue="model.answer = null" class="flex gap-2">
          <RadioGroupLabel class="hidden">{{
            t('actionCheckIn.header', { partnerName: active ? conductorStore.partner.firstName : conductorStore.member.firstName })
          }}</RadioGroupLabel>
          <RadioGroupOption
            as="template"
            v-for="(o, i) in [
              { value: true, label: t('actionCheckIn.yes') },
              { value: false, label: t('actionCheckIn.no') },
            ]"
            :key="i"
            :value="o.value">
            <div
              :class="[
                'cursor-pointer border',
                o.value === model.performed ? 'border-primary-700' : 'hover:bg-gray-100',
                'flex flex-1 items-center rounded-md px-3 py-3 text-sm font-medium transition-all ease-out',
              ]">
              <div class="flex-1 p-1 mt-0" :class="o.value === model.performed ? 'font-semibold text-primary-700' : 'font-normal'">
                {{ o.label }}
              </div>
              <div class="inline-flex items-center justify-center">
                <div
                  class="inline-flex items-center justify-center w-5 h-5 border-2 rounded-full"
                  :class="o.value === model.performed ? 'border-primary-700 bg-primary-700' : 'border-primary-400 bg-white group-hover:bg-gray-100'">
                  <CheckIcon v-if="o.value === model.performed" class="w-4 h-4 text-white" />
                </div>
              </div>
            </div>
          </RadioGroupOption>
        </RadioGroup>

        <!-- FOLLOW-UP -->
        <template v-if="followUpType === 'yes'">
          <fieldset>
            <legend class="font-medium tracking-wide" id="yes-impact-label">{{ getActionCheckInText('followUp') }}</legend>
            <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
              <label
                :for="o.label + 'impact-areas'"
                :id="o.label + '_label'"
                v-for="o in impactAreas"
                :class="[
                  'flex cursor-pointer rounded-md border p-3 text-sm focus-within:ring-2 focus-within:ring-primary-500',
                  model.impactAreas.includes(o) ? 'border-primary-700' : '',
                ]"
                :key="o">
                <span class="flex-1">
                  {{ o.label }}
                </span>

                <input
                  :id="o.label + 'impact-areas'"
                  type="checkbox"
                  v-model="model.impactAreas"
                  :value="o"
                  class="w-5 h-5 rounded-full cursor-pointer text-primary-700 focus:ring-transparent" />
              </label>
            </div>
          </fieldset>

          <fieldset>
            <legend for="action-answer" class="font-medium tracking-wide" aria-hidden>{{ getActionCheckInText('yesImpact') }}</legend>
            <RichTextBox as="div" v-model="model.answer" :placeholder="t('actionCheckIn.placeholder')" id="action-answer" class="pt-1" />
          </fieldset>
        </template>

        <div v-else-if="followUpType === 'no'">
          <fieldset>
            <legend class="font-medium tracking-wide">{{ t('actionCheckIn.noBarrier') }}</legend>
            <RichTextBox v-model="model.answer" :placeholder="t('actionCheckIn.placeholder')" />
          </fieldset>
        </div>
      </div>
      <!-- BUTTONS -->
      <div class="flex-1 px-4 mt-4 text-right md:px-0">
        <base-button v-if="active" :disabled="!canContinue" uppercase @click="advance">
          {{ t('actionCheckIn.buttonText') }}
        </base-button>
      </div>
      <div class="z-20">
        <base-exit-link />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useConductorStore } from '@/stores/conductorStore';
  import { useI18n } from 'vue-i18n';
  import { RadioGroup, RadioGroupOption } from '@headlessui/vue';
  import { CheckIcon } from '@heroicons/vue/20/solid';

  // props
  interface Props {
    content?: any;
  }

  const props = withDefaults(defineProps<Props>(), {
    content: {},
  });

  // emits
  const emits = defineEmits<{
    (event: 'advance', payload: any): void;
  }>();

  const { t } = useI18n();

  const conductorStore = useConductorStore();
  const partnerName = computed(() => conductorStore.partner.firstName);

  const GetImpactAreas = (catagories) => {
    const selectAble = catagories.map((c) => {
      return { label: c.label, value: c.value };
    });
    return selectAble;
  };

  const getActionCheckInText = (key) => {
    if (conductorStore.content.conversationActionType === 'RoleBased') {
      const role = conductorStore.member.meFirst ? 'mentor' : 'mentee';
      return t(`actionCheckIn.${role}.${key}`, { partnerName: conductorStore.partner.firstName });
    }
    return t(`actionCheckIn.${key}`, { partnerName: conductorStore.partner.firstName });
  };

  const impactAreas = reactive(GetImpactAreas(props.content.categories));

  const active = computed(() => conductorStore.isActive);

  const activeVoicePlural = computed(() => {
    return active.value ? `${partnerName.value}'s` : 'Your';
  });

  const activeVoice = computed(() => {
    return active.value ? partnerName.value : 'You';
  });

  const followUpType = computed(() => {
    if (model.performed === null) {
      return null;
    }
    return model.performed ? 'yes' : 'no';
  });

  const model = reactive({
    sourcePeerConversationId: props.content.sourcePeerConversationId,
    performed: null,
    answer: null,
    affectedRelationship: false,
    affectedImpact: false,
    affectedGrowth: false,
    impactAreas: [],
  });

  const canContinue = computed(() => {
    if (model.performed === null) {
      return false;
    } else if (model.performed === false) {
      return !!model.answer && model.answer !== '<p></p>';
    } else {
      return !!model.answer && model.answer !== '<p></p>' && model.impactAreas?.length > 0;
    }
  });

  const advance = async () => {
    emits('advance', model);
  };
</script>

