<template>
  <div ref="md"></div>
</template>

<script setup lang="ts">
  import { ref, onMounted, onUpdated } from 'vue';
  import MarkdownIt from 'markdown-it';

  const props = defineProps<{
    source: string;
    breaks?: boolean;
  }>();

  const md = ref();

  const renderMarkdown = () => {
    let markdown = new MarkdownIt().set({
      breaks: props.breaks,
    });

    md.value.innerHTML = markdown.render(props.source);
  };

  onMounted(() => renderMarkdown());

  onUpdated(() => renderMarkdown());
</script>
