import { defineStore, acceptHMRUpdate } from 'pinia';
import axiosAuthAnon from '@/plugins/axios-auth-anonymous';
import axiosAuthCore from '@/plugins/axios-auth-core';
import jwtDecode from 'jwt-decode';
import posthog from 'posthog-js';
import sentry from '@/plugins/sentry';
import { TokenEntity } from '@/Types';
interface Member {
  slug: string;
  displayName: string;
  firstName: string;
  lastName: string;
}

interface State {
  currentUser: any;
  roles: any;
}

export const useAuthStore = defineStore({
  id: 'auth',
  state: (): State => ({
    currentUser: useSessionStorage<Member>('currentUser', {} as Member),
    roles: useSessionStorage<Array<string>>('roles', []),
  }),
  getters: {
    isLoggedIn(state) {
      return state.currentUser?.displayName != null;
    },
    hasSuperAdminRole(state) {
      return state.roles.includes('super-admin');
    },
  },
  actions: {
    async signIn(email: string, password: string, timeZone: string): Promise<boolean> {
      if (this.isLoggedIn) {
        return;
      }

      const { data: signIn } = await axiosAuthAnon.post('/auth/sign-in', {
        email,
        password,
        timeZone: timeZone ?? 'America/Los_Angeles',
      });

      // store token
      sessionStorage.setItem('token', signIn.data.token);

      // get and store user
      const { data: authMe } = await axiosAuthCore.get('/auth/me');

      const user = authMe.data;
      this.currentUser = user;

      if (this.isLoggedIn) {
        posthog.identify(user.slug, {
          slug: user.slug,
          org_code: user.organization.code,
        });

        posthog.group('company', user.organization.code, {
          name: user.organization.name,
          code: user.organization.code,
        });

        sentry.setUser({ id: user.slug, email: user.email, username: user.slug });
      } else {
        sentry.setUser(null);
        posthog.reset();
      }

      const jwt: TokenEntity = jwtDecode(signIn.data.token);

      if (Array.isArray(jwt.role)) {
        this.roles = [].concat(jwt.role ?? []);
      } else {
        this.roles = [].concat(jwt.role?.split(',') ?? []);
      }

      return true;
    },
    async signInSessionToken(sessionToken: string): Promise<boolean> {
      try {
        // store token
        sessionStorage.setItem('token', sessionToken);

        // get and store user
        const { data: authMe } = await axiosAuthCore.get('/auth/handoff');

        const user = authMe.data;

        this.currentUser = user; // as UserEntity;

        posthog.identify(user.slug, {
          slug: user.slug,
          org_code: user.organization.code,
        });

        posthog.group('company', user.organization.code, {
          name: user.organization.name,
          code: user.organization.code,
        });

        sentry.setUser({ id: user.slug, email: user.email, username: user.slug });

        const jwt: TokenEntity = jwtDecode(sessionToken);

        if (Array.isArray(jwt.role)) {
          this.roles = [].concat(jwt.role ?? []);
        } else {
          this.roles = [].concat(jwt.role?.split(',') ?? []);
        }

        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async signOut() {
      sessionStorage.removeItem('token');
      this.roles = [];
      this.currentUser = {};
      sentry.setUser(null);
      posthog.reset();
      return true;
    },
    async whoAmI() {
      const { data: authMe } = await axiosAuthCore.get('/auth/me');
      this.currentUser = authMe.data;
      return this.currentUser;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
