<template>
  <component :is="computedTag" :id="id" :to="to" :type="type" :class="btnClasses" :disabled="disabled" @click="(event) => emits('click', event)">
    <slot></slot>
  </component>
</template>

<script setup lang="ts">
  import { computed } from 'vue';

  interface Props {
    type?: 'button';
    color?: 'primary' | 'primary-alt' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'text' | 'rounded';
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    flat?: boolean;
    disabled?: boolean;
    full?: boolean;
    noRing?: boolean;
    centered?: boolean;
    uppercase?: boolean;
    to?: object;
    id?: string;
  }

  const emits = defineEmits<{
    (event: 'click', payload: any): void;
  }>();

  const props = withDefaults(defineProps<Props>(), {
    type: 'button',
    color: 'primary',
    size: 'md',
    flat: false,
    disabled: false,
    uppercase: false,
    full: false,
    noRing: false,
    centered: false,
    to: null,
    id: '',
  });

  const btnClasses = computed(() => {
    return {
      'inline-flex items-center border border-transparent shadow-sm justify-center focus:ring-2 focus:ring-2 focus:ring-offset-2 focus:outline-none': true,
      'text-white bg-primary-600 hover:bg-primary-700 focus:ring-primary-500 disabled:opacity-50': props.color === 'primary',
      'text-primary-700 bg-primary-100 hover:bg-primary-200 focus:ring-primary-300 disabled:opacity-50': props.color === 'primary-alt',
      'text-white bg-green-600 hover:bg-green-500 focus:ring-green-500 disabled:opacity-50': props.color === 'success',
      'text-gray-700 bg-white border-gray-300 hover:bg-gray-50 focus:ring-gray-500 disabled:opacity-50': props.color === 'secondary',
      'text-white bg-gray-500 hover:bg-gray-300 focus:ring-gray-500 disabled:opacity-50': props.color === 'info',
      'text-white bg-yellow-400 hover:bg-yellow-300 focus:ring-yellow-500 disabled:opacity-50': props.color === 'warning',
      'text-white bg-red-600 hover:bg-red-400 focus:ring-red-500 disabled:opacity-50': props.color === 'danger',
      'bg-transparent !shadow-none text-gray-700 focus:ring-gray-500 disabled:opacity-50': props.color === 'text',
      '!p-1 text-gray-400 !rounded-full hover:bg-gray-50 hover:text-primary-800 !shadow-none focus:ring-primary-500 bg-white': props.color === 'rounded',
      'px-6 py-3 text-base font-medium rounded-md': props.size === 'xl',
      'px-6 py-2 text-base font-medium rounded-md': props.size === 'lg',
      'px-6 py-2 text-sm font-medium rounded': props.size === 'md',
      'px-3 py-2 text-sm leading-4 font-medium rounded-sm': props.size === 'sm',
      'px-2.5 py-1.5 text-xs font-medium rounded-none': props.size === 'xs',
      'disabled:cursor-default disabled:outline-none disabled:pointer-events-none disabled:shadow-none disabled:select-none': props.disabled,
      'w-full': props.full,
      'justify-center': props.centered,
      'shadow-none': props.flat,
      'no-ring': props.noRing,
      uppercase: props.uppercase,
    };
  });

  const computedTag = computed(() => {
    if (props.to != null) {
      return 'router-link';
    }

    return 'button';
  });
</script>
