<template>
  <div class="flex w-full items-center">
    <div class="flex w-full flex-row items-center">
      <div class="flex-1 text-lg">{{ question.questionText }}</div>
      <span v-for="n in maxRating" :key="n" @click="selected(n)">
        <div v-if="n > rating">
          <svg
            class="mr-1 h-4 w-4 text-gray-300 dark:text-gray-500"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 22 20">
            <path
              d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
          </svg>
        </div>
        <div v-else>
          <svg class="mr-1 h-4 w-4 text-yellow-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
            <path
              d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
          </svg>
        </div>
      </span>
    </div>
    <hr class="" />
  </div>
</template>

<script setup lang="ts">
  import { Question } from '@/components/MatchGroupQuestion/CustomQuestionTypes';

  export interface Props {
    question?: any;
  }

  const maxRating = ref(5);
  const rating = ref(0);
  const ratingsAnswer = ref();

  const props = withDefaults(defineProps<Props>(), {
    question: {
      questionText: '',
      answerType: '',
      placement: '',
      questionId: '',
      workshopId: '',
      workshopMatchGroupId: '',
      answerValue: null,
      answerDataType: 'int',
    } as Question,
  });
  const question = ref(props.question);
  const selected = (n) => {
    if (rating.value === n) {
      rating.value = 0;
      question.value.answerValue = null;
      return;
    }
    rating.value = n;
    question.value.answerValue = n;

    emits('answer', question.value);
  };

  const emits = defineEmits<{
    (event: 'answer', payload: any): void;
  }>();
</script>

<style scoped></style>

