<template>
  <div class="p-2">
    <div class="flex flex-wrap justify-start text-2xl font-bold">
      <span aria-hidden>{{ t('action_commitment.answer.my') }}</span>
      <span class="sr-only">{{ t('action_commitment.answer.my') }} Imperative</span>
      <img class="ml-2 mt-1.5 h-auto w-24" src="@/assets/images/imperative-logo.svg" alt="Imperative Logo" aria-hidden />
    </div>
    <p class="max-w-xl pb-5">{{
      t(getCommitmentAnswerTextKey('explanation'), {
        memberName: myName,
        actionTag1: areasOfCommitment[0],
        actionTag2: areasOfCommitment[1],
        actionTag3: areasOfCommitment[2],
        partnerName: myPartner,
      })
    }}</p>
    <p class="pb-2 text-sm font-bold">{{
      t(getCommitmentAnswerTextKey('main-graphic-title'), {
        partnerName: myPartner,
        actionTag1: areasOfCommitment[0],
        actionTag2: areasOfCommitment[1],
        actionTag3: areasOfCommitment[2],
      })
    }}</p>
  </div>
</template>

<script setup lang="ts">
  import { useConductorStore } from '@/stores/conductorStore';
  import { useI18n } from 'vue-i18n';
  import { useActionCommitmentStore } from '@/stores/actionCommitmentStore';

  const { t } = useI18n();

  // props
  interface Props {
    relationship: number;
    impact: number;
    growth: number;
  }
  const actionCommitmentStore = useActionCommitmentStore();
  const conductorStore = useConductorStore();

  const props = withDefaults(defineProps<Props>(), {
    relationship: 0,
    impact: 0,
    growth: 0,
  });
  const getCommitmentAnswerTextKey = (actionText: string) => {
    if (conductorStore.content?.conversationActionType === 'RoleBased') {
      return actionCommitmentStore.getCommitmentAnswerTextKey(actionText, conductorStore.member, conductorStore.content.conversationActionType);
    }
    return actionCommitmentStore.getCommitmentAnswerTextKey(actionText, conductorStore.member, null);
  };

  const myPartner = computed(() => conductorStore?.partner?.firstName);
  const myName = computed(() => conductorStore?.member?.firstName);
  const areasOfCommitment = actionCommitmentStore.getImpactAreas;

  const moods: Array<string> = ['frustrated', 'unhappy', 'meh', 'happy', 'pumped'];
</script>

