import { createRouter, createWebHistory, Router, RouteRecordRaw } from 'vue-router';
import { useConductorStore } from '@/stores/conductorStore';
import { useAuthStore } from './services/authenticationService';
import posthog from 'posthog-js';
import sentry from '@/plugins/sentry';

export const routes = [
  // Default
  {
    name: 'Default',
    path: '/',
    component: () => import('@/pages/Launch.vue'),
    beforeEnter: async (to, from) => {
      debugReroute();
      const authStore = useAuthStore();
      authStore
        .whoAmI()
        .then(() => {})
        .catch(() => {
          // something happened need to go to the members dashboard
          const url = import.meta.env.VITE_AUTHENTICATION_URL;
          window.location.href = url;
        })
        .finally(() => {
          if (!authStore.isLoggedIn) {
            // we aren't logged in need to go to the members dashboard
            const url = import.meta.env.VITE_AUTHENTICATION_URL;
            window.location.href = url;
          }
        });
    },
  },

  // Split View
  {
    name: 'SplitConduct',
    path: '/split-conduct',
    component: () => import('@/pages/SplitConduct.vue'),
  },
  {
    name: 'Launch',
    path: '/launch',
    component: () => import('@/pages/Launch.vue'),
    beforeEnter: async (to, from) => {
      debugReroute();
      const authStore = useAuthStore();
      const params = new URLSearchParams(window.location.search);

      if (params.has('access_token')) {
        const sessionToken = params.get('access_token');

        await authStore.signInSessionToken(sessionToken);
      }

      authStore
        .whoAmI()
        .then(() => {})
        .catch(() => {
          // something happened need to go to the members dashboard
          const url = import.meta.env.VITE_AUTHENTICATION_URL;
          window.location.href = url;
        })
        .finally(() => {
          if (!authStore.isLoggedIn) {
            // we aren't logged in need to go to the members dashboard
            const url = import.meta.env.VITE_AUTHENTICATION_URL;
            window.location.href = url;
          }
        });
    },
  },
  // Specific Peer Conversation
  {
    path: '/:peerConversationId',
    component: () => import('@/pages/Index.vue'),
    meta: {
      title: 'Peer Conversation Conductor',
    },
    beforeEnter: async (to, from) => {
      const authStore = useAuthStore();

      const params = new URLSearchParams(window.location.search);

      if (params.has('access_token')) {
        const sessionToken = params.get('access_token');

        await authStore.signInSessionToken(sessionToken);

        return {
          name: 'Conduct',
          params: to.params,
        };
      }
    },
  },

  // Conduct
  {
    name: 'Conduct',
    path: '/:peerConversationId/conduct',
    component: () => import('@/pages/Conduct.vue'),
    meta: {
      title: 'Peer Conversation Conductor',
    },
    beforeEnter: async (to, from, next) => {
      let allowNoCamera = sessionStorage.getItem('allowNoCamera') === 'true';

      try {
        const permission = await navigator.mediaDevices.getUserMedia({ video: true });
        allowNoCamera = sessionStorage.getItem('allowNoCamera') === 'true';

        if (!permission.active && allowNoCamera) {
          return next({ name: 'DeviceUnblock', params: to.params });
        }
      } catch (error) {
        if (!allowNoCamera) {
          return next({ name: 'DeviceUnblock', params: to.params });
        }
      }

      const conductor = useConductorStore();
      await conductor.connect(to.params.peerConversationId as string);
      
      next();
    },
  },

  {
    name: 'DeviceUnblock',
    path: '/:peerConversationId/conduct',
    component: () => import('@/pages/DeviceUnblock.vue'),
    meta: {
      title: 'Get ready for your Conversation',
    },
  },

  // Error Page
  {
    path: '/error-page',
    name: 'ErrorPage',
    props: (route) => ({ error_id: route.params.error_id }),
    component: () => import('@/pages/ErrorPage.vue'),
    meta: {
      allowAnonymous: true,
      layout: 'blank',
    },
    beforeEnter: async (to, from) => {
      const error_id = sentry.lastEventId();
      to.params.error_id = error_id;
    },
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: () => import('@/pages/SignIn.vue'),
    meta: {
      allowAnonymous: true,
      layout: 'blank',
    },
    beforeEnter: async (to, from) => {
      debugReroute();
      const authStore = useAuthStore();

      if (authStore.isLoggedIn) {
        return { name: 'Default' };
      } else {
        const params = new URLSearchParams(window.location.search);

        if (params.has('session_token')) {
          const sessionToken = params.get('session_token');

          if (await authStore.signInSessionToken(sessionToken)) {
            return { name: 'Default' };
          }
        }
      }
    },
  },

  // Sign Out
  {
    path: '/sign-out',
    name: 'SignOut',
    component: () => import('@/pages/SignIn.vue'),
    meta: {
      allowAnonymous: true,
    },
    beforeEnter: (to) => {
      debugReroute();
      const authStore = useAuthStore();

      authStore.signOut();

      return {
        name: 'SignIn',
        query: to.query,
      };
    },
  },
] as Array<RouteRecordRaw>;

export const router = createRouter({
  history: createWebHistory(),
  routes,
}) as Router;

// navigation guard - check authorization
import checkAuth from '@/middleware/checkAuth';
import { useDebugStore } from '@/stores/debug';

router.beforeEach((to) => {
  return checkAuth(to);
});

router.afterEach((to) => {
  nextTick(() => {
    posthog.capture('$pageview', {
      $current_url: to.fullPath,
    });
  });
});

const debugReroute = () => {
  const debugStore = useDebugStore();
  if (!debugStore.showDebug) {
    const url = import.meta.env.VITE_AUTHENTICATION_URL;
    window.location.href = url;
  }
};
export default router;
