export enum PhaseType {
  IntroTips = 'IntroTips',
  ConversationStart = 'ConversationStart',
  CheckIn = 'CheckIn',
  RelationshipQuestion = 'RelationshipQuestion',
  InAWord = 'InAWord',
  ActionCheckIn = 'ActionCheckIn',
  ActionCommitment = 'ActionCommitment',
  ConversationQuestion = 'ConversationQuestion',
  Scheduler = 'Scheduler',
  ReviewNotes = 'ReviewNotes',
  Finished = 'Finished',
  MatchStatusCheckIn = 'MatchStatusCheckIn',
  SelfSelect = 'SelfSelect',
  MatchingGroupPreOutcomeQuestion = 'MatchingGroupPreOutcomeQuestion',
  MatchingGroupPostOutcomeQuestion = 'MatchingGroupPostOutcomeQuestion',
  RoleBasedIntroModal = 'RoleBasedIntroModal',
}

