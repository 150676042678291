<template>
  <div class="flex flex-col px-4 gap-y-3">
    <h3 class="text-lg font-semibold leading-6 text-gray-900">Update Your Match Status</h3>
    <p class="text-base px-4">
      You are nearing completion of conversations with {{ conductorStore.partner.firstName }}. Let us know if you would like to be matched again for a new
      series of conversations.
    </p>
    <div v-if="!needsUpdate">
      <h2 class="text-base font-semibold leading-6 text-gray-900">Current match status:</h2>
      <div :class="statusClass" class="w-full max-w-md mx-auto flex-row rounded-xl p-2 text-center mt-2">
        <div class="text-center text-lg font-bold capitalize" aria-label="Current match status">{{ content.currentStatus }}</div>
      </div>
    </div>
    <div class="w-full pt-4" v-else>
      <div class="w-full">
        <Listbox v-model="newStatus" as="div">
          <ListboxLabel class="block text-sm font-medium leading-6 text-gray-900">Select your new status</ListboxLabel>
          <Float
            as="div"
            class="relative w-full mt-2"
            placement="bottom-start"
            :offset="4"
            :flip="10"
            floating-as="template"
            enter="transition duration-200 ease-out"
            enter-from="scale-95 opacity-0"
            enter-to="scale-100 opacity-100"
            leave="transition duration-150 ease-in"
            leave-from="scale-100 opacity-100"
            leave-to="scale-95 opacity-0"
            tailwindcss-origin-class>
            <ListboxButton
              class="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left border focus:outline-none focus-visible:border-primary-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-primary-300 sm:text-sm">
              <span class="block truncate">{{ newStatus }}</span>
              <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </ListboxButton>

            <ListboxOptions
              class="absolute z-10 mt-1 max-h-60 w-full max-w-full md:max-w-xl overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <ListboxOption
                v-slot="{ active, selected }"
                v-for="status in matchStatusOptions"
                :key="status.value"
                :value="status.value"
                as="template"
                :aria-label="status.alt">
                <ul>
                  <li :class="[active ? 'bg-primary-100 text-primary-900' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-10 pr-4']">
                    <span :class="[selected ? 'font-medium' : 'font-normal', 'block truncate']">
                      {{ status.text }}<span class="text-gray-500 text-sm">{{ ' ' }}-{{ ' ' }}{{ status.alt }} </span>
                    </span>
                    <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3 text-primary-600">
                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </li>
                </ul>
              </ListboxOption>
            </ListboxOptions>
          </Float>
        </Listbox>
      </div>
      <div v-if="newStatus == MatchStatuses.ReadyForMatch" class="pt-4">
        <label class="text-base font-medium" for="matchStatus">Help us learn</label>
        <div class="relative mt-2">
          <input
            class="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left border border-gray-200 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-primary-300 sm:text-sm"
            name="matchStatus"
            id="matchStatus"
            v-model.trim="matchStatus"
            maxlength="255"
            placeholder="What are you looking forward to for your next match?" />
        </div>
      </div>
      <div v-else-if="newStatus == MatchStatuses.NotReadyForMatch" class="mt-2">
        <Listbox v-model="unmatchStatus" as="div">
          <ListboxLabel class="block text-sm font-medium leading-6 text-gray-900">Select reason</ListboxLabel>
          <Float
            as="div"
            class="relative w-full mt-2"
            placement="bottom-start"
            :offset="4"
            :flip="10"
            floating-as="template"
            enter="transition duration-200 ease-out"
            enter-from="scale-95 opacity-0"
            enter-to="scale-100 opacity-100"
            leave="transition duration-150 ease-in"
            leave-from="scale-100 opacity-100"
            leave-to="scale-95 opacity-0"
            tailwindcss-origin-class>
            <ListboxButton
              class="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left border focus:outline-none focus-visible:border-primary-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
              <span class="block truncate">{{ unmatchStatus }}</span>
              <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </ListboxButton>

            <ListboxOptions
              class="absolute z-10 mt-1 max-h-60 w-full max-w-full md:max-w-xl overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <ListboxOption v-slot="{ active, selected }" v-for="reason in reasonOptions" :key="reason" :value="reason" as="template">
                <ul>
                  <li :class="[active ? 'bg-primary-100 text-primary-900' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-10 pr-4']">
                    <span :class="[selected ? 'font-medium' : 'font-normal', 'block truncate']">{{ reason }}</span>
                    <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3 text-primary-600">
                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </li>
                </ul>
              </ListboxOption>
            </ListboxOptions>
          </Float>
        </Listbox>
      </div>
    </div>
    <div class="pt-4 text-right">
      <base-button @click="toggleStatusSelect()"> {{ needsUpdate ? 'Cancel' : 'Needs An Update' }}</base-button>
      <base-button class="ml-2" @click="advance()"> {{ needsUpdate ? 'Update' : 'Looks Good!' }}</base-button>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { MatchStatuses } from '@/Types';
  import { useConductorStore } from '@/stores/conductorStore';
  import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
  import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
  // props
  interface Props {
    content: any;
  }

  const props = withDefaults(defineProps<Props>(), {
    content: {},
  });

  const conductorStore = useConductorStore();

  const reasonOptions = [
    'Leave of absence',
    'Competing work priorities',
    'Personal priorities',
    'Starting a new role',
    'Leaving organization',
    "Didn't meet expectations",
    'Other',
  ];

  const needsUpdate = ref(false);
  const newStatus = ref(MatchStatuses.ReadyForMatch);

  const matchStatus = ref('');
  const unmatchStatus = ref(reasonOptions[0]);

  const matchStatusOptions = [
    {
      value: MatchStatuses.ReadyForMatch,
      text: MatchStatuses.ReadyForMatch,
      alt: 'I want to be matched with a new partner',
    },
    {
      value: MatchStatuses.NotReadyForMatch,
      text: MatchStatuses.NotReadyForMatch,
      alt: "Don't match me with a new partner at this time",
    },
  ];

  const statusClass = computed(() => {
    switch (props.content.currentStatus.toLocaleLowerCase()) {
      case MatchStatuses.Undeclared.toLocaleLowerCase():
        return 'bg-yellow-300';
      case MatchStatuses.ReadyForMatch.toLocaleLowerCase():
        return 'bg-green-300';
      case MatchStatuses.NotReadyForMatch.toLocaleLowerCase():
        return 'bg-red-400';
      default:
        return 'bg-gray-300';
    }
  });

  const toggleStatusSelect = async () => {
    needsUpdate.value = !needsUpdate.value;

    if (!needsUpdate.value) {
      newStatus.value = props.content.currentStatus;
    }
  };

  const emits = defineEmits<{
    (event: 'advance', payload: any): void;
  }>();

  const advance = async () => {
    emits('advance', {
      needsUpdate: needsUpdate.value,
      newStatus: newStatus.value,
      newStatusReason: newStatus.value == MatchStatuses.ReadyForMatch ? matchStatus.value : unmatchStatus.value,
    });
  };
</script>
