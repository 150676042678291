import { posthog } from '@/plugins/posthog';
import { useDebugStore } from './debug';
import { defineStore, acceptHMRUpdate } from 'pinia';
import { SignalRService } from '@/middleware/signalr';
import { useSignalRStore } from '@/stores/signalr';
import axiosCore from '@/plugins/axios-core';
import type { Conversation, PartnerObject, QuestionsEntity } from '@/Types';
import { useLayoutStore } from './layoutStore';
import { useToast } from 'vue-toastification';
import { Organization } from '@/Types';

interface State {
  signalr: SignalRService | null;
  peerConversationId: string | null;
  member: PartnerObject | null;
  partner: PartnerObject | null;
  conversation: Conversation | null;
  questions?: QuestionsEntity[] | null;
  questionCount?: number | null;
  organization?: Organization | null;
  partnerConnected: boolean;
  currentPhase: null | any;
  partnerPhase: null | any;
  isActive: boolean;
  finishedMembers: Array<any>;
  titleOverride?: string | null;
  content: null | any;
  // language: string;

  // advancingPhase: boolean;
}

export const useConductorStore = defineStore({
  id: 'conductor',
  state: (): State => ({
    signalr: null,
    peerConversationId: null,
    member: null,
    partner: null,
    conversation: null,
    questions: null,
    questionCount: null,
    organization: null,
    partnerConnected: false,
    titleOverride: null,
    currentPhase: null,
    partnerPhase: null,
    isActive: true,
    finishedMembers: [],

    content: null,
  }),
  getters: {
    isConnected: (state) => state.signalr.getConnectionStatus() ?? false,
  },
  actions: {
    async init(peerConversationId: string) {
      // console.log('initial connection for %s', peerConversationId);
      this.peerConversationId = peerConversationId;
    },
    async connect(peerConversationId: string) {
      // set basic conversation information
      this.peerConversationId = peerConversationId;

      var values = await Promise.all([
        axiosCore.get('partners', { params: { pcid: peerConversationId } }),
        axiosCore.get('conversation', { params: { pcid: peerConversationId } })
      ]);

      const partnerResponse = values[0].data;
      const questionsResponse = values[1].data;

      this.member = partnerResponse.member;
      this.partner = partnerResponse.partner;
      this.conversation = partnerResponse.peerConversation;
      this.questions = questionsResponse.questions;
      this.questionCount = questionsResponse.questions.length;
      this.organization = partnerResponse.organization;

      posthog.identify(this.member.slug, { slug: this.member.slug, org_code: this.organization.code });
      posthog.group('company', this.organization.code, { org_code: this.organization.code });

      // start signalr stuff
      const signalr = useSignalRStore();
      this.signalr = await signalr.connect();

      signalr.on('PartnerDisconnected', (arg) => {
        // console.log('PartnerDisconnected');
        // this.partnerConnected = false;
      });

      signalr.on('PartnerConnected', (arg) => {
        this.partnerConnected = true;
      });

      const layoutStore = useLayoutStore();
      const debugStore = useDebugStore();

      signalr.on('BroadcastPhases', async (arg: any) => {
        // debug message
        if (debugStore.showDebug) {
          console.log('BroadcastPhases');
          console.log(arg);
        }

        // // set content - only if present in collection - MOVED
        // // console.log(arg.memberContent);
        // const memberContent = arg.memberContent[this.member.slug];
        // if (memberContent) {
        //   this.content = memberContent;
        // }
        var conversationPhases = arg.conversationPhases;

        // set members who are finished with the current phase
        this.finishedMembers = conversationPhases.finishedMembers;

        // set indicator for active user
        this.isActive = conversationPhases.activeMemberSlug === this.member.slug;

        // set the partner's phase info
        this.partnerPhase = conversationPhases.memberPhases[this.partner.slug];

        // check whether member phase has changed
        const newPhase = conversationPhases.memberPhases[this.member.slug];
        const startNewPhase =
          !this.currentPhase ||
          newPhase.phaseType !== this.currentPhase.phaseType ||
          newPhase.contentRecordId !== this.currentPhase.contentRecordId ||
          newPhase.contentRecordKey !== this.currentPhase.contentRecordKey;

        const newContent = newPhase.phaseMode === 'Alternating' && JSON.stringify(arg.memberContent[this.member.slug]) !== JSON.stringify(this.content);

        const memberContent = arg.memberContent[this.member.slug];
        if (memberContent) {
          this.content = memberContent;
          this.questionCount = memberContent.totalQuestionCount ?? memberContent.questions?.length ?? 3;
        }

        // start new phase
        if (startNewPhase) {
          // set phase information
          this.currentPhase = newPhase;

          // trigger new phase
          await layoutStore.startPhase(this.currentPhase.phaseType, this.currentPhase.contentRecordId, this.currentPhase.contentRecordKey);
        }
      });

      try {
        await signalr.invoke('JoinConversation', { peerConversationId });
      } catch (error) {
        console.error(error)
        handleResponseError(error);
        throw error;
      }
    },

    // moves the phase forward (e.g. finishing it or changing active user)
    async advancePhase(model: any = {}) {
      try {
        await this.signalr.invoke('AdvancePhase', {
          peerConversationId: this.peerConversationId,
          data: JSON.stringify(model),
        });
      } catch (error) {
        handleResponseError(error);
        throw error;
      }
    },

    // rechecks to see if partner is connected
    async checkPartnerConnection() {
      try {
        await this.signalr.invoke('CheckPartnerConnection', {
          peerConversationId: this.peerConversationId,
        });
      } catch (error) {
        handleResponseError(error);
        throw error;
      }
    },
    // reset phase (debugging)
    async resetPhases() {
      try {
        await this.signalr.invoke('ResetConversation', {
          peerConversationId: this.peerConversationId,
        });
      } catch (error) {
        handleResponseError(error);
        throw error;
      }
    },

    setTitle(title: string) {
      this.content = {title: title, ActiveMember: true}
    },

    // notifies a partner that the member is waiting to start conversation.
    async conversationStartedMessage(state) {
      const { data: questionsResponse } = await axiosCore.post('conversation/sendStartNotification', { params: { memberSlug: state.member.slug } });
    },
    async messageMyPartner() {
      try {
        const hasMessagedMyPartner = sessionStorage.getItem('hasMessagedMyPartner') !== 'sent';
        if (hasMessagedMyPartner) {
          await axiosCore.post(`conversation/${this.peerConversationId}/sendpartnernotification/${this.member.slug}`);
          sessionStorage.setItem('hasMessagedMyPartner', 'sent');
        }
      } catch (ex) {
        console.error(ex);
      }
    },
  },
});

const toast = useToast();

function handleResponseError(error) {
  console.error(error);
  if (error.message.includes('NotFoundException')) {
    // console.log('404 error');
    toast.error('Requested resource cannot be found');
  } else if (error.message.includes('UnauthorizedException')) {
    // console.log('404 error');
    toast.error(`You have not been authorized to perform this action`);
  } else {
    // console.log('unknown error');
    toast.error('There was an error with the response');
  }
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useConductorStore, import.meta.hot));
}
