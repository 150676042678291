<template>
  <div class="flex h-full flex-col px-4">
    <div class="flex-1 space-y-4">
      <flow-panel :active-tab="active ? 0 : 1"></flow-panel>
      <div v-if="active">
        <CommitmentAsk @formValidEvent="isValidHandler" />
      </div>
      <div v-else>
        <ActionCommitmentAnswer :relationship="content.relationship" :impact="content.impact" :growth="content.growth" />
      </div>
    </div>
    <div class="mt-4 flex-1 text-right">
      <base-button v-if="active" class="flex-shrink" data-testid="btnNext" :disabled="!canContinue" @click="advance">Next</base-button>
    </div>
    <div class="z-20">
      <base-exit-link />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useConductorStore } from '@/stores/conductorStore';
  import { useI18n } from 'vue-i18n';
  import ActionCommitmentAnswer from '@/components/ActionCommitment/ActionCommitmentAnswer.vue';
  import { useActionCommitmentStore } from '@/stores/actionCommitmentStore';
  import { AreasOfCommitment } from '@/Types';

  const { t } = useI18n();
  const conductorStore = useConductorStore();
  const actionCommitmentStore = useActionCommitmentStore();
  const commitmentData = ref();
  const active = computed(() => conductorStore.isActive);
  const canContinue = ref(false);

  // props
  interface Props {
    content: AreasOfCommitment;
  }

  const props = withDefaults(defineProps<Props>(), {
    content: null,
  });

  actionCommitmentStore.setOptions(props.content);

  const emits = defineEmits<{
    (event: 'advance', payload: any): void;
  }>();

  const advance = async () => {
    emits('advance', commitmentData.value.commitment);
  };

  const isValidHandler = (commitment) => {
    canContinue.value = commitment.isValid;
    if (canContinue) {
      commitmentData.value = commitment;
    } else {
      commitmentData.value = null;
    }
  };
</script>
