<template>
  <div class="flex flex-col">
    <div class="flex justify-between border-b">
      <h2
        class="w-full pb-2 text-sm text-center uppercase md:text-base"
        :class="[(props.activeTab ?? 0) === 0 ? activeClasses : 'border-transparent font-medium text-gray-500']"
        ref="tabAsk"
        role="status"
        :aria-selected="(props.activeTab ?? 0) === 0"
        aria-controls="active-panel"
        aria-live="polite"
        :aria-hidden="(props.activeTab ?? 0) !== 0">
        {{ t('flowPanel.askType') }}
      </h2>
      <div class="h-full py-3 border-l border-gray-300"></div>
      <h2
        class="w-full pb-2 text-sm text-center uppercase md:text-base"
        :class="[(props.activeTab ?? 0) === 1 ? activeClasses : 'border-transparent font-medium text-gray-500']"
        ref="tabAnswer"
        role="status"
        :aria-selected="(props.activeTab ?? 1) === 1"
        aria-controls="passive-panel"
        aria-live="polite"
        :aria-hidden="(props.activeTab ?? 1) !== 1">
        {{ t('flowPanel.answer') }}
      </h2>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, watch } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useFirstTimeTipsStore } from '@/stores/firstTimeTipsStore';
  import { useConductorStore } from '@/stores/conductorStore';
  import { useLayoutStore } from '@/stores/layoutStore';

  const { t } = useI18n();

  const props = defineProps({
    activeTab: Number,
  });

  const firstTimeTipsStore = useFirstTimeTipsStore();
  const conductorStore = useConductorStore();
  const layoutStore = useLayoutStore();

  // elements to attach tippy
  const tabAsk = ref();
  const tabAnswer = ref();

  // tippy instances
  let askTippy = null;
  let answerTippy = null;

  // flags to show/hide tips
  const showQuestionAskTip = computed(() => {
    return firstTimeTipsStore.isFirstQuestion && conductorStore.member.finishedConversationCount === 0 && conductorStore.isActive;
  });

  const showQuestionAnswerTip = computed(() => {
    return firstTimeTipsStore.isFirstQuestion && conductorStore.member.finishedConversationCount === 0 && !conductorStore.isActive;
  });

  onMounted(() => {
    // create tippy instances (hidden by default)
    askTippy = firstTimeTipsStore.getTippy(tabAsk, { content: t('first_time_tool_tip.ask_question') });
    answerTippy = firstTimeTipsStore.getTippy(tabAnswer, { content: t('first_time_tool_tip.answer_question') });

    // show/hide tips
    updateAskTippy();
    updateAnswerTippy();
  });

  const updateAskTippy = () => {
    if (showQuestionAskTip.value) {
      askTippy.show();
    } else {
      askTippy.hide();
    }
  };
  const updateAnswerTippy = () => {
    if (showQuestionAnswerTip.value) {
      answerTippy.show();
    } else {
      answerTippy.hide();
    }
  };

  // tips watchers
  watch(
    () => showQuestionAskTip.value,
    () => {
      if (askTippy) {
        updateAskTippy();
      }
    }
  );
  watch(
    () => showQuestionAnswerTip.value,
    () => {
      if (answerTippy) {
        updateAnswerTippy();
      }
    }
  );

  const activeClasses = computed(() => {
    if (layoutStore.currentPhase && (layoutStore.currentPhase.agendaValue === 0 || layoutStore.currentPhase.agendaValue === 999)) {
      return 'border-b-4 border-b-green-600 font-semibold';
    } else {
      return 'border-b-4 border-b-pink-600 font-semibold';
    }
  });
</script>
