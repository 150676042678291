import { defineStore } from 'pinia';
import { AreasOfCommitment, PartnerObject } from '@/Types';

export interface ImpactOption {
  text: string;
}

interface State {
  areasOfCommitment: AreasOfCommitment;
}

export const useActionCommitmentStore = defineStore({
  id: 'actionCommitmentStore',
  state: (): State => ({
    areasOfCommitment: null,
  }),
  getters: {
    getImpacts(state) {
      return impacts;
    },
    getRelationshipAreasToImpact(state) {
      return relationShipImpactOptions;
    },
    getAreasToImpact(state) {
      return impactOptions;
    },
    getAreasOfGrowth(state) {
      return growthOptions;
    },
    getContent(state) {
      return state.areasOfCommitment;
    },
    getImpactAreas(state) {
      return state.areasOfCommitment.areasOfCommitment.map((val) => val.title);
    },
    getActionToImpact: (state) => {
      return (title: string) => {
        const categories = state.areasOfCommitment.areasOfCommitment.filter((cat) => {
          if (cat.title === title) {
            return cat;
          }
        });
        let cloneCategories = JSON.parse(JSON.stringify(categories));

        return cloneCategories && cloneCategories.length > 0 ? cloneCategories[0].subInvestments : [];
      };
    },
    getCommitmentAnswerTextKey: (state) => {
      return (textKey: string, member: PartnerObject, conversationType: string) => {
        if (conversationType === 'RoleBased') {
          const key_start = member.meFirst ? 'action_commitment.answer.mentor' : 'action_commitment.answer.mentee';
          return key_start + '.' + textKey;
        }
        return 'action_commitment.answer.' + textKey;
      };
    },
    getCommitmentTextKey: (state) => {
      return (textKey: string, member: PartnerObject, conversationType: string) => {
        if (conversationType === 'RoleBased') {
          const key_start = member.meFirst ? 'action_commitment_roles.mentor' : 'action_commitment_roles.mentee';
          return key_start + '.' + textKey;
        }
        return 'action_commitment' + '.' + textKey;
      };
    },
  },
  actions: {
    setOptions(options: AreasOfCommitment) {
      this.areasOfCommitment = options;
    },
  },
});

const impacts = {
  options: [
    { text: 'action_commitment.btn_my_relationships', value: 'relationships' },
    { text: 'action_commitment.btn_my_impact', value: 'impact' },
    { text: 'action_commitment.btn_my_growth', value: 'growth' },
  ],
};

const relationShipImpactOptions = {
  title: 'options_relationships.which_relationship',
  options: [
    { text: 'options_relationships.btn_outside_team', to: 'outside_team' },
    { text: 'options_relationships.btn_manager', to: 'manager' },
    { text: 'options_relationships.btn_direct_reports', to: 'direct_reports' },
    { text: 'options_relationships.btn_team', to: 'team' },
    { text: 'options_relationships.btn_customer', to: 'customer' },
    { text: 'options_relationships.btn_network_outside_org', to: 'network_out_side_org' },
    { text: 'options_relationships.btn_leadership', to: 'leadership' },
    { text: 'options_relationships.btn_other', to: 'other' },
  ],
};

const impactOptions = {
  title: 'options_impact.where_to_impact',
  options: [
    { text: 'options_impact.btn_outside_team', to: 'outside_team' },
    { text: 'options_impact.btn_team', to: 'team' },
    { text: 'options_impact.btn_customer', to: 'customer' },
    { text: 'options_impact.btn_my_org', to: 'my_org' },
    { text: 'options_impact.btn_society', to: 'society' },
    { text: 'options_impact.btn_other', to: 'other' },
  ],
};

const growthOptions = {
  title: 'options_growth.which_area_for_growth',
  options: [
    { text: 'options_growth.btn_strategic_thinking', to: 'strategic_thinking' },
    { text: 'options_growth.btn_skills', to: 'skills' },
    { text: 'options_growth.btn_tech_functional_skills', to: 'tech_func_skills' },
    { text: 'options_growth.btn_leadership_skills', to: 'leadership_skills' },
    { text: 'options_growth.bt_interpersonal_skills', to: 'interpersonal_skills' },
    { text: 'options_growth.bt_health_well_being', to: 'health_well_being' },
    { text: 'options_growth.bt_career_driven_direction', to: 'career_driven_direction' },
    { text: 'options_growth.btn_other', to: 'other' },
  ],
};
