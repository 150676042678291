import { createApp } from 'vue';
import i18n from './i18n';

import App from './App.vue';
import router from './router';

import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import { createHead } from '@vueuse/head';

import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';

// import tippy
import VueTippy from 'vue-tippy';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

import './assets/index.postcss';
import './assets/index.scss';

import FontAwesomeIcon from './fontawesom-icons';
import { setupSentry } from './plugins/sentry';

const head = createHead();
const pinia = createPinia();
const app = createApp(App);

pinia.use(piniaPluginPersistedstate);

import registerFilters from '@/middleware/registerFilters';
registerFilters(app);

app.use(pinia);

app.use(router);

app.use(i18n);
app.use(head);
app.use(Donut);
app.use(VueTippy, {
  directive: 'tippy',
  component: 'tippy',
  defaultProps: { appendTo: document.getElementById('tooltip_container') },
});

// import toastification
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
app.use(Toast, {
  position: 'bottom-right',
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: 'button',
  icon: true,
  rtl: false,
});

import posthogPlugin from './plugins/posthog';
import { Options } from '@sentry/vue/types/types';
app.use(posthogPlugin);

const sentry = setupSentry(app);

// FONT AWESOME
app.component('font-awesome-icon', FontAwesomeIcon);

sentry.attachErrorHandler(app, { attachProps: true, logErrors: true, debug: import.meta.env.DEV } as Options);

app.mount('#app');

import PrimeVue from 'primevue/config';
import Calendar from 'primevue/calendar';
import Slider from 'primevue/slider';
import 'primevue/resources/themes/aura-light-green/theme.css';

app.use(PrimeVue);
app.component('Calendar', Calendar);
app.component('Slider', Slider);

