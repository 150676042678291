<template>
  <div class="flex flex-col h-full">
    <div class="grow">
      <!-- QUESTION -->

      <h2
        id="relationship-question-text"
        class="mb-4 text-base font-semibold text-center text-gray-700 break-normal md:text-xl"
        data-testid="question-text"
        aria-hidden="true"
        >{{ t(question, { partnerName }) }}</h2
      >

      <!-- ANSWER: RADIO -->
      <template v-if="props.content.questionType === 'list-single'  ">
        <RadioGroup v-model="model.answers[0]" as="div" aria-describedby="relationship-question-text">
          <RadioGroupLabel class="hidden" aria-hidden="false">{{ t(question, { partnerName }) }}</RadioGroupLabel>
          <div class="space-y-4">
            <RadioGroupOption as="template" v-for="o in options" :key="o.text" :value="o.value" v-slot="{ checked, active }">
              <div
                :class="[
                  checked ? 'border-transparent ring-2 ring-primary-600' : 'border-gray-300',
                  active ? 'border-primary-600 ring-2 ring-primary-600' : '',
                  'relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between',
                ]"
                :data-testid="o.text">
                <div class="flex items-center justify-between w-full">
                  {{ t(o.text, { partnerName }) }}
                  <div class="inline-flex items-center justify-center">
                    <div
                      class="inline-flex items-center justify-center w-6 h-6 border-2 rounded-full"
                      :class="checked ? 'border-primary-700 bg-primary-700' : 'border-primary-400 bg-white group-hover:bg-gray-50'">
                      <CheckIcon v-if="checked" class="w-4 h-4 text-white" />
                    </div>
                  </div>
                </div>
              </div>
            </RadioGroupOption>
          </div>
        </RadioGroup>
      </template>

      <!-- ANSWER: CHECKBOXES -->
      <template v-else-if="props.content.questionType === 'list-multiple'">
        <ul class="space-y-4" aria-describedby="relationship-question-text">
          <li v-for="(o, idx) in options" :key="idx">
            <input
              :id="`chkbx-${idx}`"
              type="checkbox"
              v-model="model.answers"
              :value="o.value"
              :aria-labelledby="`chk-${o.text}-label`"
              class="sr-only peer"
              required="false" />
            <label
              :class="[
                model.answers.includes(o.value) ? 'peer-checked:border-primary-600' : 'hover:bg-gray-50 peer-focus:border-primary-400',
                'group relative flex cursor-pointer justify-between rounded-lg border-2 bg-white px-6 py-4 shadow-sm',
              ]"
              :for="`chkbx-${idx}`">
              <div>
                {{ t(o.text, { partnerName }) }}
              </div>
              <div class="inline-flex items-center justify-center">
                <div
                  class="inline-flex items-center justify-center w-6 h-6 border-2 rounded-full"
                  :class="model.answers.includes(o.value) ? 'border-primary-700 bg-primary-700' : 'border-primary-400 bg-white group-hover:bg-gray-50'">
                  <CheckIcon v-if="model.answers.includes(o.value)" class="w-4 h-4 text-white" />
                </div>
              </div>
            </label>
          </li>
        </ul>
      </template>

      <!-- ANSWER: SLIDER -->
      <template v-else>
        <div class="mt-16">
          <!-- <n-config-provider :theme-overrides="themeOverrides">
            <n-slider
              role="slider"
              v-model:value="model.answers[0]"
              :min="sliderMin"
              :max="sliderMax"
              :show-tooltip="true"
              step="mark"
              :format-tooltip="formatTooltip"
              :default-value="sliderMid"
              :marks="sliderMarks" />
          </n-config-provider> -->
          <RadioGroup v-model="model.answers[0]" as="div" aria-describedby="relationship-question-text">
          <RadioGroupLabel class="hidden" aria-hidden="false">{{ t(question, { partnerName }) }}</RadioGroupLabel>
          <div class="space-y-4">
            <RadioGroupOption as="template" v-for="o in options" :key="o.text" :value="o.value" v-slot="{ checked, active }">
              <div
                :class="[
                  checked ? 'border-transparent ring-2 ring-primary-600' : 'border-gray-300',
                  active ? 'border-primary-600 ring-2 ring-primary-600' : '',
                  'relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between',
                ]"
                :data-testid="o.text">
                <div class="flex items-center justify-between w-full">
                  {{ t(o.text, { partnerName }) }}
                  <div class="inline-flex items-center justify-center">
                    <div
                      class="inline-flex items-center justify-center w-6 h-6 border-2 rounded-full"
                      :class="checked ? 'border-primary-700 bg-primary-700' : 'border-primary-400 bg-white group-hover:bg-gray-50'">
                      <CheckIcon v-if="checked" class="w-4 h-4 text-white" />
                    </div>
                  </div>
                </div>
              </div>
            </RadioGroupOption>
          </div>
        </RadioGroup>
        </div>
      </template>

      <!-- BUTTON -->
      <div class="flex items-center justify-center mt-8">
        <!-- class="text-white uppercase rounded text-normal bg-primary-500 hover:bg-primary-700" -->
        <base-button :disabled="!canContinue" uppercase @click="advance" data-testid="btn-begin">
          {{ t('relationshipQuestion.button') }}
        </base-button>
      </div>
    </div>
    <base-exit-link class="mt-8" />
  </div>
</template>

<script setup lang="ts">
  import { computed } from 'vue';
  import { useConductorStore } from '@/stores/conductorStore';
  import { useI18n } from 'vue-i18n';
  import { CheckIcon } from '@heroicons/vue/20/solid';
  import { NSlider } from 'naive-ui';
  import { NConfigProvider } from 'naive-ui';

  const themeOverrides = {
    common: {
      primaryColor: '#4F62C8',
    },
  };

  // props
  interface Props {
    content?: any;
  }

  const props = withDefaults(defineProps<Props>(), {
    content: {},
  });

  // emits
  const emits = defineEmits<{
    (event: 'advance', payload: any): void;
  }>();

  const { t } = useI18n();

  const conductorStore = useConductorStore();
  const partnerName = computed(() => conductorStore.partner.firstName);

  const question = computed(() => props.content.question);
  const options = computed(() => props.content.answers);

  const model = reactive({
    answers: [],
  });

  const sliderMin = computed(() => {
    if (!options || !options.value || !options.value.length) {
      return 0;
    }
    return Math.min(...options.value.map((el) => el.value));
  });
  const sliderMax = computed(() => {
    if (!options || !options.value || !options.value.length) {
      return 4;
    }
    return Math.max(...options.value.map((el) => el.value));
  });
  const sliderMid = computed(() => {
    return ((sliderMax.value - sliderMin.value) / 2) + sliderMin.value;
  });
  const sliderMarks = computed(() => {
    if (!options || !options.value || !options.value.length) {
      return null;
    }
    const marks = {};
    options.value.forEach((el) => (marks[el.value] = null));
    return marks;
  });

  const formatTooltip = (value: number) => {
    if (!options || !options.value || !options.value.length) {
      return '';
    }
    const option = options.value.find((el) => el.value == value);
    if (!option) {
      return '';
    }
    return t(option.text, { partnerName });
  };

  const canContinue = computed(() => model.answers && model.answers.length);

  const advance = async () => {
    emits('advance', model);
  };
</script>

