import axiosCore from '@/plugins/axios-core';
import { defineStore, acceptHMRUpdate } from 'pinia';

export const useDebugStore = defineStore({
  id: 'debug',
  getters: {
    showDebug() {
      return import.meta.env.VITE_SHOW_DEV_DEBUG === 'true' || window.localStorage.getItem('showDebug') === 'true';
    },
    showDebugBar() {
      return import.meta.env.VITE_SHOW_DEBUG_BAR === 'true' || window.localStorage.getItem('showDebugBar') === 'true';
    },
  },
  actions: {
    async get(peerConversationId: string, reset: boolean) {
      const { data: questionsResponse } = await axiosCore.get('debug', {
        params: {
          pcid: peerConversationId,
          reset: reset,
        },
      });
      return questionsResponse;
    },

    async getRandom(reset: boolean) {
      const { data: questionsResponse } = await axiosCore.get('debug/lucky', {
        params: {
          reset: reset,
        },
      });
      return questionsResponse;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDebugStore, import.meta.hot));
}
