export default function formatPercent(
  value: number | bigint,
  options: any = {},
  defaultVal: number | bigint = 0): string {

  const combinedOptions = {
    style: 'percent',
    ...options,
  };
  return new Intl.NumberFormat('en-US', combinedOptions).format(value ?? defaultVal);
}
