<template>
  <router-view></router-view>
</template>

<script setup lang="ts">
  import { useIdle, tryOnUnmounted } from '@vueuse/core';
  import { useAuthStore } from '@/services/authenticationService';
  import { useDevicesList } from '@vueuse/core';

  const isSupported = useDevicesList({ requestPermissions: true });

  const openFreshdesk = () => {
    (window as any).FreshworksWidget && (window as any).FreshworksWidget('show');
  };

  const { idle } = useIdle(import.meta.env.VITE_APP_TIME_OUT_IN_MINUTES * 60000); // timout in minutes

  const unwatchIdleCheck = watch(idle, (newValue) => {
    if (newValue) {
      useAuthStore().signOut();
      const url = import.meta.env.VITE_AUTHENTICATION_URL;
      window.location.href = url;
    }
  });
  // remove it when we unmount the App
  tryOnUnmounted(() => {
    unwatchIdleCheck();
  });
  // open freshdesk widget (production)
  const debug = import.meta.env.DEV;

  if (!debug) {
    openFreshdesk();
  }
</script>
