<template>
  <div class="mt-8 flex items-start gap-x-6">
    <div class="flex-shrink-0">
      <img src="/images/aaron.png" alt="assistant welcome" class="max-w-[8rem]" />
    </div>
    <div class="flex flex-col gap-4">
      <div class="text-xl font-bold">Your guided conversation is complete and your notes are ready for review.</div>
      <div v-if="!content.isLastConversation">
        Together you scheduled your next conversation, {{ content.nextScheduledTitle }}, for
        <time class="font-semibold" :datetime="content.nextScheduledDateTime" data-testid="next-schedule-date">
          {{ $filters.formatDate(nextScheduledDateTime, 'MMM dd, yyyy h:mm aa') }} </time
        >.
        <p>A calendar invite has been sent.</p>
      </div>
      <div class="justify-left mt-4 flex items-center">
        <base-button class="text-normal rounded bg-primary-500 px-6 py-2 uppercase text-white hover:bg-primary-700" @click="advance">
          Exit Conversation & Review Notes
        </base-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useVideoStore } from '@/stores/videoStore';
  import { useConductorStore } from '@/stores/conductorStore';

  // props
  interface Props {
    content?: {
      isLastConversation?: boolean;
      nextScheduledTitle?: string;
      nextScheduledDateTime?: string;
    };
  }

  const props = defineProps<Props>();
  const conductorStore = useConductorStore();
  const nextScheduledDateTime = computed(() => {
    if (content.value.nextScheduledDateTime && conductorStore.member.timeZone) {
      let date = new Date(content.value.nextScheduledDateTime);
      return new Date(date.toLocaleString('en-US', { timeZone: conductorStore.member.timeZone }));
    } else {
      return null;
    }
  });

  const { content } = toRefs(props);
  const videoStore = useVideoStore();

  // emits
  const emits = defineEmits<{
    (event: 'advance', payload: any): void;
  }>();

  const advance = () => {
    videoStore.disconnect();
    emits('advance', {});
  };
</script>

