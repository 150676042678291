// import { useAuthStore } from '@/services/authenticationService'
import { RouteLocationNormalized } from 'vue-router';

// navigation guard - verify authentication & authorization
export default async function checkAuth(to: RouteLocationNormalized) {
  const allowAnonymous = to.matched.some((route) => route.meta.allowAnonymous);

  if (allowAnonymous) {
    return true;
  }

  // const authStore = useAuthStore();

  // if (authStore.isLoggedIn) {
  //   // if not admin or super-admin, not authorized and redirect
  //   if (!authStore.hasSuperAdminRole) { // && !authStore.hasPortalRole) {
  //     location.href = 'https://app.imperative.com';
  //     return false;
  //   }

  //   return authStore
  //     .whoAmI()
  //     .then(() => {
  //       return true;
  //     })
  //     .catch(() => {
  //       return redirectToSignIn();
  //     });
  // }

  // // authorized, so continue to route
  // return redirectToSignIn();

  // function redirectToSignIn() {
  //   return { name: 'SignOut', query: { redirectFrom: to.fullPath } };
  // }

  return true;
}
