import { defineStore, acceptHMRUpdate } from 'pinia';
import { useDevicesList } from '@vueuse/core';

export type DeviceSettings = {
  isSupported: boolean;
  permissionsGranted: boolean;
  blurBackground: boolean;
  // user devices
  devices: Array<MediaDeviceInfo> | null;
  // user selected devices
  audioInputDevice: MediaDeviceInfo | null;
  audioOutputDevice: MediaDeviceInfo | null;
  videoInputDevice: MediaDeviceInfo | null;
};

export const useDeviceSettingsStore = defineStore(
  'deviceSettings',
  () => {
    const {
      videoInputs: cameras,
      audioInputs: microphones,
      audioOutputs: speakers,
      isSupported,
      permissionGranted,
    } = useDevicesList({ requestPermissions: true });

    const blurBackground = ref<boolean>(false);

    const audioInputDevice = ref(null) as Ref<MediaDeviceInfo | null>;
    const audioOutputDevice = ref(null) as Ref<MediaDeviceInfo | null>;
    const videoInputDevice = ref(null) as Ref<MediaDeviceInfo | null>;

    watchEffect(() => {
      if (!audioInputDevice.value?.deviceId && microphones.value?.length > 0) {
        audioInputDevice.value = microphones.value[0];
      }

      if (!audioOutputDevice.value?.deviceId && speakers.value?.length > 0) {
        audioOutputDevice.value = speakers.value[0];
      }

      if (!videoInputDevice.value?.deviceId && cameras.value?.length > 0) {
        videoInputDevice.value = cameras.value[0];
      }
    });

    return {
      cameras,
      microphones,
      speakers,

      isSupported,
      permissionGranted,
      blurBackground,

      audioInputDevice,
      audioOutputDevice,
      videoInputDevice,
    } as const;
  },
  {
    persist: {
      storage: localStorage,
      paths: ['blurBackground'],
    },
  }
);

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDeviceSettingsStore, import.meta.hot));
}
