<template>
  <div v-if="show" class="z-20 inline-block">
    <button class="cursor-pointer text-xs uppercase text-primary-600 hover:underline" @click="exitConversation">Save and Exit Conversation</button>
  </div>
</template>

<script lang="ts" setup>
  import { useConductorStore } from '@/stores/conductorStore';
  import { Container, useLayoutStore } from '@/stores/layoutStore';
  import { useVideoStore } from '@/stores/videoStore';

  const conductorStore = useConductorStore();
  const layoutStore = useLayoutStore();
  const videoStore = useVideoStore();

  const show = computed(() => layoutStore.container !== Container.Modal);

  const exitConversation = () => {
    videoStore.disconnect();
    const peerConversationId = conductorStore.peerConversationId;
    const url = import.meta.env.VITE_DASHBOARD_URL;
    window.location.href = url;
  };
</script>
