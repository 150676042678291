<template>
  <div class="flex flex-col flex-1 px-4 mt-4 md:px-0">
    <div class="flex-1 space-y-4">
      <flow-panel :active-tab="active ? 0 : 1"></flow-panel>
      <div class="flex gap-x-6">
        <div class="flex-shrink-0 hidden md:block">
          <img src="/images/aaron.png" alt="assistant welcome" class="max-w-[8rem]" aria-hidden />
        </div>
        <div class="flex flex-col gap-4">
          <div class="text-2xl font-bold text-primary">
            {{ t(props.content.title) }}
          </div>
          <div class="text-normal">{{ t(props.content.intro) }} </div>
          <div class="text-base font-semibold md:text-lg">
            <p>{{ t(props.content.question, { partnerName: active ? partnerName : myName }) }}</p>
          </div>
          <!-- ACTIVE -->
          <div v-if="active">
            <input class="w-[100%] max-w-md rounded border px-3 py-2 xl:w-[50%]" v-model.trim="oneWord" maxlength="20" placeholder="Type partner's one word..." />
          </div>
          <!-- INACTIVE -->
          <div v-else class="text-gray-500">{{ t(props.content.inactive, { partnerName: active ? myName : partnerName }) }} </div>
        </div>
      </div>
    </div>
    <div class="flex-1 pt-2 text-right">
      <base-button v-if="active" :disabled="!canContinue" @click="advance">{{ t(props.content.buttonText) }}</base-button>
    </div>
    <base-exit-link />
  </div>
</template>

<script setup lang="ts">
  import { useConductorStore } from '@/stores/conductorStore';
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';

  // props
  interface Props {
    content: any;
  }
  const props = withDefaults(defineProps<Props>(), {
    content: {},
  });

  // emits
  const emits = defineEmits<{
    (event: 'advance', payload: any): void;
  }>();

  const { t } = useI18n();

  const active = computed(() => conductorStore.isActive);
  const myName = computed(() => conductorStore.member.firstName);
  const partnerName = computed(() => conductorStore.partner.firstName);

  const oneWord = ref(null);

  const canContinue = computed(() => !!oneWord.value);

  // phases
  const conductorStore = useConductorStore();

  const advance = async () => {
    emits('advance', {
      type: conductorStore.currentPhase.contentRecordId,
      word: oneWord.value,
    });
  };
</script>
