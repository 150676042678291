import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

// bold
import {
  faBold as fasBold,
  faItalic as fasItalic,
  faList as fasList,
  faListOl as fasListOl,
  faHeading as fasHeading,
  faQuoteRight as fasQuoteRight,
  faCircle as fasCircle,
  faCheck as fasCheck,
} from '@fortawesome/pro-solid-svg-icons';

library.add(fasBold);
library.add(fasItalic);
library.add(fasList);
library.add(fasListOl);
library.add(fasHeading);
library.add(fasQuoteRight);
library.add(fasCircle);
library.add(fasCheck);

// duotone
import {
  faComment as fadComment,
  faCommentDots as fadCommentDots,
  faUser as fadUser,
  faBullseyeArrow as fadBullseyeArrow,
  faFlaskRoundPotion as fadFlaskRoundPotion,
} from '@fortawesome/pro-duotone-svg-icons';

library.add(fadComment);
library.add(fadCommentDots);
library.add(fadUser);
library.add(fadFlaskRoundPotion);
library.add(fadBullseyeArrow);

// Regular
import { faCloudSlash as farCloudSlash } from '@fortawesome/pro-regular-svg-icons';
library.add(farCloudSlash);

export default FontAwesomeIcon;
