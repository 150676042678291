<template>
  <div class="flex flex-col px-4 gap-y-3">
    <div>
      <div class="p-4">
        <p class="text-base p-4 ">
          {{ rt(pageLang['body'], {firstName}) }}

        </p>
        <ul class="list-disc list-inside px-4">
          <li v-for="bullet in pageLang['bullets']">{{ rt(bullet) }}</li>
        </ul>
      </div>
      <div class="pt-4 text-right">
        <base-button v-if="stepIdx > 0" class="ml-2" @click="advance(-1)">Back</base-button>
        <base-button class="ml-2" @click="advance(1)"> {{ stepIdx < 2 ? 'Next' : 'Begin!' }}</base-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { PhaseProps, PhaseEmits, PhaseEmitName } from '@/Types';
  import { useConductorStore } from '@/stores/conductorStore';

  const props = withDefaults(defineProps<PhaseProps>(), {});
  const emits = defineEmits<PhaseEmits>();
  const { tm, rt, t } = useI18n();

  let stepIdx = ref(0);

  const conductorStore = useConductorStore();

  const firstName = conductorStore.member.firstName;
  const role = conductorStore.member.meFirst ? 'mentor' : 'mentee'
  const bulletKey = computed(() => `roleBasedIntroModal.${role}.${stepIdx.value}`)
  const pageLang = computed(() => tm(bulletKey.value));

  watch(bulletKey, () => {
    conductorStore.$state.titleOverride = rt(tm(bulletKey.value)['title'])
  }, {immediate: true})

  const advance = ((increment) => {
    if(stepIdx.value > 1 && increment > 0){
      conductorStore.$state.titleOverride = null
      emits(PhaseEmitName.advance, {} );
    }
    else{
      stepIdx.value += increment;
    }
  });
</script>
