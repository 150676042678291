<template>
  <div>
    <Calendar
      :class="invalid == true ? 'text-orange-700 line-through' : ''"
      v-if="model"
      :modelValue="model"
      iconDisplay="input"
      showIcon
      showTime
      hourFormat="12"
      @update:modelValue="emits('update:modelValue', $event)"
      :minDate="minDate"
      invalid />
  </div>
</template>

<script setup lang="ts">
  import { add, getTime, set } from 'date-fns';
  import { ref } from 'vue';

  const themeOverrides = {
    common: {
      primaryColor: '#4F62C8',
    },
  };

  // props
  interface Props {
    modelValue: Date;
    disabled: boolean;
    isFirst: boolean;
    previousDateTime: Date;
  }

  const props = withDefaults(defineProps<Props>(), {
    modelValue: null,
    disabled: true,
    isFirst: true,
    previousDateTime: null,
  });

  const model = ref();
  const minDate = ref(new Date());
  const invalid = ref(false);
  // emits
  const emits = defineEmits<{
    (event: 'update:modelValue', payload: Date | Date[]): void;
  }>();

  onMounted(() => {
    model.value = new Date(props.modelValue);
    invalid.value = model.value <= minDate.value ? true : false;
  });

  watch(
    () => props.modelValue,
    () => {
      model.value = props.modelValue;
      invalid.value = model.value <= minDate.value ? true : false;
    }
  );

  const dateDisabled = (ts: number) => {
    const date = set(new Date(ts), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    const previousDate = set(new Date(props.previousDateTime), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    return date < previousDate;
  };

  const timeDisabled = (ts: number) => {
    return {
      isHourDisabled: (hour: number) => checkTime(ts, hour, 0, 1),
      isMinuteDisabled: (minute: number, hour: number) => checkTime(ts, hour, minute, 2),
      isSecondDisabled: () => false,
    };
  };

  const checkTime = (ts: number, hour: number, minute: number = 0, type: number): boolean => {
    const date = set(new Date(ts), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    const time = set(new Date(ts), { year: 0, month: 0, date: 0, hours: hour, minutes: minute, seconds: 0, milliseconds: 0 });
    const previousDate = set(new Date(props.previousDateTime), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    const previousTime = add(set(new Date(props.previousDateTime), { year: 0, month: 0, date: 0 }), { hours: 0 });
    const disabledTime = date < previousDate;
    const checkTime = getTime(date) === getTime(previousDate);

    if (disabledTime) {
      return true;
    } else if (checkTime) {
      if (type === 1) {
        return getTime(time) <= getTime(previousTime);
      } else {
        return getTime(time) < getTime(add(previousTime, { hours: 1 }));
      }
    } else {
      return false;
    }
  };

  const shortcuts = computed(() => ({
    '2 Weeks': getTime(add(new Date(props.previousDateTime), { weeks: 2 })),
  }));
</script>

<style lang="scss">
  .n-date-panel button {
    color: white !important;
    background-color: rgb(63, 77, 135) !important;
    border-color: rgb(63, 77, 135) !important;
  }
  .n-date-panel button:hover {
    color: white !important;
    background-color: rgba(63, 77, 135, 0.8) !important;
    border-color: rgba(63, 77, 135, 0.8) !important;
  }

  .n-date-panel-actions {
    button {
      @apply h-7;
      .n-button__content {
        @apply text-base;
      }
    }
  }
</style>

