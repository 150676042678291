
import { defineStore } from "pinia";
import { useTippy } from "vue-tippy";
import {PhaseType} from "@/Types/PhaseType";


// only count alternating tyoes
// first instance of altenating types
// second instance of alternating types
//
// getters:
// 4 getters one for each tools tip
// 1) is this my first convo
// 2) what alternating phase are we on
// 3) which half of the phase are we one?

interface State {
    phaseCount: number;
    currentPhaseType: null;
}

export const useFirstTimeTipsStore = defineStore({
    id: 'useFirstTimeTips',
    state: (): State => ({
        phaseCount: 0,
        currentPhaseType: null,
    }),
    getters: {
        isFirstQuestion(state) {
            return (state.phaseCount === 1 && state.currentPhaseType === PhaseType.ConversationQuestion);
        },
        currentPhaseCount(state) {
            return state.phaseCount;
        },
        getCurrentPhaseType(state) {
            return state.currentPhaseType;
        },
        isConversationQuestionPhaseType(state) {
          return state.currentPhaseType === PhaseType.ConversationQuestion
        }
    },
    actions: {
        // incrementPhaseCount() {
        //     this.phaseCount++;
        // },
        // decrementPhaseCount() {
        //     this.phaseCount--;
        // },
        setPhaseCount(value:number)
        {
            this.phaseCount = value;
        },
        setCurrentPhase(type:PhaseType)
        {
            this.currentPhaseType = type;
        },

        getTippy(el, binding) {
            const tippy = useTippy(el, {
                delay: [100, 200],
                trigger: "manual",
                hideOnClick: false,
                interactive: true,
                // showOnCreate: binding.showOnCreate ?? true,
                showOnCreate: false,
                allowHTML: true,
                placement: binding.placement ?? "top",
                content: `<div class="grid grid-cols-4 "><div class="col-span-3">${binding.content}</div> <button class="col-span-1 btnClose ml-9 text-white background-transparent font-bold">X</button></div></div>`,
                theme: 'first_time_tooltip',
                triggerTarget: binding.triggerTarget,
                onShow(instance) {
                    instance.popper.querySelector('.btnClose').addEventListener('click', () => {
                        instance.hide();
                    });
                },
                onHide(instance) {
                    instance.popper.querySelector('.btnClose').removeEventListener('click', () => {
                        instance.hide();
                    });
                },
            });
            return tippy;
        }
    }

});
