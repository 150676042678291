<template>
  <div class="flex h-full flex-col">
    <div class="grow">
      <div class="grid gap-4 sm:grid-cols-3">
        <div class="flex justify-center">
          <img src="/images/aaron.png" alt="assistant welcome" class="max-w-[8rem]" />
        </div>
        <div class="sm:col-span-2">
          <div>
            {{ t(props.content.content) }}
          </div>
        </div>
      </div>
      <div class="mt-8 flex items-center justify-center">
        <base-button uppercase @click="emits('advance', {})">
          {{ t(props.content.buttonText) }}
        </base-button>
      </div>
    </div>
    <base-exit-link class="mt-8" />
  </div>
</template>

<script setup lang="ts">
  // import { useConductorStore } from '@/stores/conductorStore.js';
  import { useI18n } from 'vue-i18n';

  // const conductorStore = useConductorStore();

  const i18n = useI18n();
  // i18n.locale.value = conductorStore.language;
  const { t } = i18n;

  // props
  interface Props {
    content: any;
  }

  const props = withDefaults(defineProps<Props>(), {
    content: {},
  });

  // emits
  const emits = defineEmits<{
    (event: 'advance', payload: any): void;
  }>();
</script>
