import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import createAuthRefreshInterceptor, { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh';
import { useAuthStore } from '@/services/authenticationService';
import { router } from '@/router';
import { RouteRecordRaw } from 'vue-router';

axios.defaults.withCredentials = true;

function getAccessToken() {
  const token = sessionStorage.getItem('token');
  return token;
}

const axiosAuthCore = axios.create({
  baseURL: import.meta.env.VITE_COREAPI_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  responseType: 'json',
} as AxiosRequestConfig);

// request interceptor
axiosAuthCore.interceptors.request.use((request) => {
  const token = getAccessToken();
  request.headers.Authorization = `Bearer ${token}`;

  return request;
});

// refresh token
const refreshAuthLogic = async (failedRequest: AxiosError) => {
  if (failedRequest && failedRequest.response && failedRequest.response.status === 401) {
    try {
      const tokenResponse = await axiosAuthCore.post('/auth/refresh-token', null, { skipAuthRefresh: true } as AxiosAuthRefreshRequestConfig);

      if (tokenResponse.data) {
        const { token } = tokenResponse.data.data;
        sessionStorage.setItem('token', token);
        failedRequest.response.config.headers.Authorization = 'Bearer ' + token;
      } else {
        useAuthStore().signOut();
        return router.push({ name: 'SignOut' } as RouteRecordRaw);
      }
    } catch (error: any) {
      return Promise.reject(error);
    }
  } else {
    sessionStorage.removeItem('token');
  }
};

createAuthRefreshInterceptor(axiosAuthCore, refreshAuthLogic, {
  statusCodes: [401],
  pauseInstanceWhileRefreshing: false, // According to docs, `false` is default value, but in fact it's not :-)
});

// response interceptor
axiosAuthCore.interceptors.response.use(
  (response) => response,
  async (error) => {
    const request = error.config;

    if ((request as AxiosAuthRefreshRequestConfig) && (request as AxiosAuthRefreshRequestConfig).skipAuthRefresh) {
      location.href = '/sign-out';
      return Promise.reject(error);
    }

    if (error && error.response && error.response.status === 401) {
      // let's retry
      return await axiosAuthCore(request);
    }

    throw error;
  }
);

export default axiosAuthCore;
