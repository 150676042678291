import { useConductorStore } from '@/stores/conductorStore';
import { acceptHMRUpdate, defineStore } from 'pinia';
import ConversationStartVue from '@/components/ConversationStart/ConversationStart.vue';
import PreConvoCheckInVue from '@/components/ConversationCheckin/PreConvoCheckIn.vue';
import InAWordVue from '@/components/InAWord/InAWord.vue';
import RelationshipQuestionVue from '@/components/RelationshipQuestion/RelationshipQuestion.vue';
import SchedulerVue from '@/components/Scheduler/Scheduler.vue';
import ReviewNotesVue from '@/components/ReviewNotes/ReviewNotes.vue';
import Finished from '@/components/Finished/Finished.vue';
import ConversationVue from '@/components/Conversation/Conversation.vue';
import ActionCheckInVue from '@/components/ActionCheckIn/ActionCheckIn.vue';
import SelfSelect from '@/components/SelfSelect/SelfSelect.vue';
import RoleBasedIntroModalVue from '@/components/RoleBasedIntroModal/RoleBasedIntroModal.vue';
import IntroTipVue from '@/components/IntroTip/IntroTip.vue';
import { useFirstTimeTipsStore } from '@/stores/firstTimeTipsStore';
import { PhaseType } from '@/Types/PhaseType';
import ActionCommitment from '@/components/ActionCommitment/ActionCommitment.vue';
import MatchStatusCheckIn from '@/components/MatchStatusCheckIn/MatchStatusCheckIn.vue';
import sentry from '@/plugins/sentry';
import { posthog } from '@/plugins/posthog';
import CustomQuestions from '@/components/MatchGroupQuestion/CustomQuestions.vue';
import { PhaseEmits, PhaseProps } from '@/Types';

// PHASE DEFINITIONS

export enum Container {
  // Left = 'Left',
  // Right = 'Right',
  Split = 'Split',
  Modal = 'Modal',
  Full = 'Full',
}

// BASE
abstract class Phase {
  phaseType: PhaseType | null = null;
  component: any | null = null;
  showVideo = true;
  container: Container = Container.Split;
  title = '';
  agendaValue = 0;
  // typeId: number | null = null;
}

class PhaseConversationIntroTips extends Phase {
  phaseType = PhaseType.IntroTips;
  component = markRaw(IntroTipVue);
  container = Container.Modal;
  agendaValue = 0;
}

class PhaseConversationStart extends Phase {
  phaseType = PhaseType.ConversationStart;
  component = markRaw(ConversationStartVue);
  container = Container.Modal;
  agendaValue = 0;
}

class PhaseConversationCheckIn extends Phase {
  phaseType = PhaseType.CheckIn;
  component = markRaw(PreConvoCheckInVue);
  container = Container.Modal;
  agendaValue = 0;
}

class PhaseConversationRelationshipQuestion extends Phase {
  phaseType = PhaseType.RelationshipQuestion;
  component = markRaw(RelationshipQuestionVue);
  container = Container.Modal;
  // showVideo = true;
  agendaValue = 0;
}

class PhaseConversationInAWord extends Phase {
  phaseType = PhaseType.InAWord;
  component = markRaw(InAWordVue);
  agendaValue = 0;
}

class PhaseConversationActionCheckIn extends Phase {
  phaseType = PhaseType.ActionCheckIn;
  component = markRaw(ActionCheckInVue);
  agendaValue = 0;
}

class PhaseConversationQuestion extends Phase {
  phaseType = PhaseType.ConversationQuestion;
  component = markRaw(ConversationVue);
  agendaValue = 1;
}

class PhaseConversationScheduler extends Phase {
  phaseType = PhaseType.Scheduler;
  component = markRaw(SchedulerVue);
  agendaValue = 999;
}

class PhaseConversationReviewNotes extends Phase {
  phaseType = PhaseType.ReviewNotes;
  component = markRaw(ReviewNotesVue);
  agendaValue = 999;
}

class PhaseConversationFinished extends Phase {
  phaseType = PhaseType.Finished;
  component = markRaw(Finished);
  agendaValue = 999;
}

class PhaseConversationActionCommitment extends Phase {
  phaseType = PhaseType.ActionCommitment;
  component = markRaw(ActionCommitment);
  agendaValue = 999;
}

class PhaseMatchStatusCheckIn extends Phase {
  phaseType = PhaseType.MatchStatusCheckIn;
  component = markRaw(MatchStatusCheckIn);
  container = Container.Modal;
  agendaValue = 0;
}

class PhaseConversationSelfSelect extends Phase {
  phaseType = PhaseType.SelfSelect;
  component = markRaw(SelfSelect);
  agendaValue = 1;
}

class MatchingGroupPreOutcomeQuestion extends Phase {
  phaseType = PhaseType.MatchingGroupPreOutcomeQuestion;
  component = markRaw(CustomQuestions);
  container = Container.Modal;
  agendaValue = 0;
}

class MatchingGroupPostOutcomeQuestion extends Phase {
  phaseType = PhaseType.MatchingGroupPostOutcomeQuestion;
  component = markRaw(CustomQuestions);
  container = Container.Split;
  agendaValue = 999;
}

class RoleBasedIntroModal extends Phase {
  phaseType = PhaseType.RoleBasedIntroModal;
  component = markRaw(RoleBasedIntroModalVue);
  container = Container.Modal;
  agendaValue = 1
}

// STORE DEFINITION

interface State {
  currentPhase: Phase | null;
  phaseCount: number;
}

export const useLayoutStore = defineStore({
  id: 'layout',
  state: (): State => ({
    currentPhase: null,
    phaseCount: 0,
  }),
  getters: {
    name: (state) => (state.currentPhase ? state.currentPhase.phaseType.toString() : ''),
    component: (state) => (state.currentPhase ? state.currentPhase.component : null),
    showVideo: (state) => (state.currentPhase ? state.currentPhase.showVideo : true),
    container: (state) => (state.currentPhase ? state.currentPhase.container : Container.Split),
    getPhaseCount: (state) => state.phaseCount,
  },
  actions: {
    async startPhase(phaseType: string, typeId: number, typeKey: string) {
      const conductorStore = useConductorStore();
      const firstTimeTipsStore = useFirstTimeTipsStore();
      firstTimeTipsStore.setCurrentPhase(phaseType as PhaseType);

      posthog.capture('$pageview', {
        phaseType: phaseType,
      });

      sentry.addBreadcrumb({ category: 'Phase', message: phaseType, level: 'debug' });

      switch (phaseType as PhaseType) {
        case PhaseType.IntroTips:
          this.currentPhase = new PhaseConversationIntroTips();
          break;
        case PhaseType.ConversationStart:
          this.currentPhase = new PhaseConversationStart();
          break;
        case PhaseType.CheckIn:
          this.currentPhase = new PhaseConversationCheckIn();
          break;
        case PhaseType.RelationshipQuestion:
          this.currentPhase = new PhaseConversationRelationshipQuestion();
          break;
        case PhaseType.InAWord:
          this.currentPhase = new PhaseConversationInAWord();
          this.currentPhase.agendaValue = typeId === 0 ? 0 : 999;
          break;
        case PhaseType.ActionCheckIn:
          this.currentPhase = new PhaseConversationActionCheckIn();
          break;
        case PhaseType.SelfSelect:
          this.currentPhase = new PhaseConversationSelfSelect();
          break;
        case PhaseType.RoleBasedIntroModal:
          this.currentPhase = new RoleBasedIntroModal();
          break;
        case PhaseType.ConversationQuestion:
          this.currentPhase = new PhaseConversationQuestion();
          this.currentPhase.agendaValue = typeId;
          firstTimeTipsStore.setPhaseCount(typeId);
          break;
        case PhaseType.Scheduler:
          this.currentPhase = new PhaseConversationScheduler();
          break;
        case PhaseType.ReviewNotes:
          this.currentPhase = new PhaseConversationReviewNotes();
          break;
        case PhaseType.Finished:
          this.currentPhase = new PhaseConversationFinished();
          await nextTick();

          // redirect to dashboard notes
          const peerConversationId = conductorStore.peerConversationId;
          const url = import.meta.env.VITE_FINISH_REDIRECT_URL + `${peerConversationId}`;
          window.location.href = url;
          break;
        case PhaseType.ActionCommitment:
          this.currentPhase = new PhaseConversationActionCommitment();
          break;
        case PhaseType.MatchStatusCheckIn:
          this.currentPhase = new PhaseMatchStatusCheckIn();
          break;
        case PhaseType.MatchingGroupPreOutcomeQuestion:
          this.currentPhase = new MatchingGroupPreOutcomeQuestion();
          break;
        case PhaseType.MatchingGroupPostOutcomeQuestion:
          this.currentPhase = new MatchingGroupPostOutcomeQuestion();
          break;
      }
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLayoutStore, import.meta.hot));
}

