<template>
  <div class="flex h-full flex-col px-4 md:px-0">
    <div class="grow">
      <!-- INTRO CONTENT -->
      <div class="flex items-center gap-x-6">
        <div class="flex-shrink-0">
          <img src="/images/aaron.png" alt="assistant welcome" class="max-w-[8rem]" />
        </div>
        <div class="flex max-w-2xl flex-col gap-4">
          <div>
            {{ t('scheduler.intro', { partnerName }) }}
          </div>
        </div>
      </div>

      <!-- INACTIVE MEMBER -->
      <template v-if="!conductorStore.isActive">
        <div class="mt-8 flex justify-center border-t border-gray-200 pt-6 text-sm text-gray-500">
          <div>
            {{ t('scheduler.inactive', { partnerName }) }}
          </div>
        </div>
      </template>

      <!-- ACTIVE MEMBER -->
      <template v-else>
        <h3 class="mb-2 mt-4 text-left text-lg font-semibold text-gray-700">
          {{ t('scheduler.header_active') }}
        </h3>

        <div v-if="showChangeMessage" class="my-4 flex flex-col rounded-md bg-orange-100 p-4 text-orange-900">
          <div>{{ t('scheduler.message_updated') }}</div>
          <div class="text-center">
            <base-button class="text-normal mt-4 rounded bg-orange-600 px-6 py-2 uppercase text-white hover:bg-orange-700" @click="restore">
              {{ t('scheduler.message_updated_button_undo') }}
            </base-button>
            <base-button class="text-normal ml-4 mt-4 rounded bg-orange-600 px-6 py-2 uppercase text-white hover:bg-orange-700" @click="closeChangeMessage">
              {{ t('scheduler.message_updated_button_ok') }}
            </base-button>
          </div>
        </div>

        <table class="block min-w-full overflow-y-auto xl:block">
          <caption class="sr-only mb-2 mt-4 text-left text-lg font-semibold text-gray-700">
            {{ t('scheduler.header_active_descriptive') }}
          </caption>
          <thead class="border-b bg-white">
            <tr>
              <th scope="col" class="px-6 py-4 text-left text-sm font-medium text-gray-900"> </th>
              <th scope="col" class="px-6 py-4 text-center text-sm font-normal text-gray-900">
                {{ t('scheduler.header_member') }}
                <br /><span class="font-medium">{{ memberTimeZone }}</span></th
              >
              <th scope="col" class="px-6 py-4 text-center text-sm font-normal text-gray-900">
                {{ t('scheduler.header_partner', { partnerName }) }}
                <br /><span class="font-medium">{{ partnerTimeZone }}</span></th
              >
            </tr>
          </thead>
          <tbody>
            <tr v-for="(c, i) in model.dates" class="border-b" :class="getBackgroundColor(c)">
              <td class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                <div class="flex flex-1 items-center px-4">
                  <div>
                    <input
                      v-if="i !== 0"
                      :id="`conversation-${i}`"
                      v-model="c.isScheduled"
                      :name="`conversation-${i}`"
                      type="checkbox"
                      class="h-4 w-4 rounded border-gray-300"
                      :class="getTextColor(c)"
                      @change="processCheckbox(c.conversationNumber)"
                      :disabled="i === 0" />
                  </div>
                  <div :class="i !== 0 ? 'ml-4' : 'ml-8'">
                    <label :for="`conversation-${i}`" class="font-medium" :class="getTextColor(c)">
                      {{ t('scheduler.label_conversation', { conversationNumber: c.conversationNumber }) }}
                    </label>
                  </div>
                </div>
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                <div class="ml-8 font-semibold" :class="getTextColor(c)">
                  <scheduler-picker
                    class="min-w-[200px]"
                    :modelValue="new Date(c.scheduledDateTime)"
                    @update:modelValue="updateScheduledDateTime(c.conversationNumber, $event)"
                    :disabled="!c.isScheduled"
                    :isFirst="i === 0"
                    :previousDateTime="new Date(getPreviousDateTime(c.conversationNumber))" />
                </div>
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                <div class="ml-8 text-gray-300" :class="getTextColor(c)" v-if="c.partnerDateTime">
                  {{ $filters.formatDate(c.partnerDateTime, 'MM/dd/yyyy hh:mm aa') }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- BUTTON -->
        <div class="mt-4 flex items-center justify-center">
          <base-button :disabled="!canContinue" class="text-normal rounded bg-primary-500 px-6 py-2 uppercase text-white hover:bg-primary-700" @click="advance">
            {{ formIsDirty ? t('scheduler.button_dirty') : t('scheduler.button') }}
          </base-button>
        </div>
      </template>
    </div>
    <base-exit-link class="mt-8" />
  </div>
</template>

<script setup lang="ts">
  import { useConductorStore } from '@/stores/conductorStore';
  import { useI18n } from 'vue-i18n';
  import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
  import { add, formatISO, getTime, isPast, parseISO, set } from 'date-fns';

  // props
  interface Props {
    content?: any;
  }

  const props = withDefaults(defineProps<Props>(), {
    content: {},
  });

  // emits
  const emits = defineEmits<{
    (event: 'advance', payload: any): void;
  }>();

  const { t } = useI18n();

  const conductorStore = useConductorStore();
  const partnerName = computed(() => conductorStore.partner.firstName);

  const memberTimeZone = conductorStore.member.timeZone;
  const partnerTimeZone = conductorStore.partner.timeZone;

  const conversationCount = computed(() => conductorStore.conversation.conversationCount);
  const conversationOrder = computed(() => conductorStore.conversation.conversationOrder);

  const showChangeMessage = ref(false);
  const changedConversationNumbers = ref([]);

  const getBackgroundColor = (c) => {
    if (changedConversationNumbers.value.includes(c.conversationNumber)) {
      return 'bg-orange-100';
    } else if (c.isScheduled) {
      return 'bg-green-100';
    } else {
      return '';
    }
  };

  const getTextColor = (c) => {
    if (changedConversationNumbers.value.includes(c.conversationNumber)) {
      return 'text-orange-700';
    } else if (c.isScheduled) {
      return 'text-green-700';
    } else {
      return '';
    }
  };

  const formIsDirty = computed(() => {
    // updated data structure
    const mappedDateTimes = model.dates.map((el) => ({
      peerConversationId: el.peerConversationId,
      scheduledDateTime: el.isScheduled ? formatISO(el.scheduledDateTime) : null,
      scheduledTimeZone: el.isScheduled ? el.scheduledTimeZone : null,
    }));

    // original data structure
    let originalDateTimes = props.content.conversations
      .filter((el) => model.dates.find((el2) => el2.peerConversationId === el.peerConversationId))
      .map((el) => ({
        peerConversationId: el.peerConversationId,
        scheduledDateTime: el.scheduledDateTime ? formatISO(parseISO(`${el.scheduledDateTime}Z`)) : null,
        scheduledTimeZone: el.scheduledTimeZone,
      }));

    // compare
    return JSON.stringify(originalDateTimes) !== JSON.stringify(mappedDateTimes);
  });

  onMounted(() => {
    initDates();
  });

  const initDates = () => {
    model.dates = [];

    let checkForScheduled = true;
    // let lastScheduledDateTime = getTime(new Date(conductorStore.conversation.scheduledDateTime));
    let lastScheduledDateTime = getTime(set(new Date(), { minutes: 0, seconds: 0, milliseconds: 0 })); // UTC timestamp
    let scheduleConversations = props.content.conversations || [];
    scheduleConversations = scheduleConversations.sort((a, b) => {
      return a - b;
    });
    for (let i = conversationOrder.value; i < conversationCount.value; i++) {
      const scheduleInfo = props.content.conversations[i];

      // look for previously scheduled
      if (checkForScheduled && scheduleInfo && scheduleInfo.scheduledDateTime) {
        lastScheduledDateTime = pushDateScheduled(scheduleInfo.peerConversationId, i + 1, scheduleInfo.scheduledDateTime);
      }
      // once we hit an unscheduled conversation, calculate proposed date/times
      else {
        lastScheduledDateTime = pushDateUnscheduled(scheduleInfo.peerConversationId, i + 1, lastScheduledDateTime);
        checkForScheduled = false;
      }
    }
  };

  const pushDateScheduled = (peerConversationId, conversationNumber, scheduledDateTime) => {
    const dateTime = new Date(`${scheduledDateTime}Z`);

    const zonedDateTime = utcToZonedTime(dateTime, memberTimeZone);
    const zonedPartnerDateTime = utcToZonedTime(dateTime, partnerTimeZone);

    model.dates.push({
      peerConversationId: peerConversationId,
      conversationNumber: conversationNumber,
      isScheduled: true,
      scheduledDateTime: getTime(zonedDateTime),
      scheduledTimeZone: memberTimeZone,
      partnerDateTime: zonedPartnerDateTime,
    });

    return getTime(dateTime); // UTC timestamp
  };

  const pushDateUnscheduled = (peerConversationId, conversationNumber, lastScheduledDateTime) => {
    const newDateTime = add(lastScheduledDateTime, { weeks: 2 });

    const zonedDateTime = utcToZonedTime(newDateTime, memberTimeZone);
    const zonedPartnerDateTime = utcToZonedTime(newDateTime, partnerTimeZone);

    model.dates.push({
      peerConversationId: peerConversationId,
      conversationNumber: conversationNumber,
      isScheduled: model.dates.length === 0, // set the first record to true
      scheduledDateTime: getTime(zonedDateTime),
      scheduledTimeZone: memberTimeZone,
      partnerDateTime: zonedPartnerDateTime,
    });

    return getTime(newDateTime); // UTC timestamp
  };

  const processCheckbox = (conversationNumber: number) => {
    const newValue = model.dates.find((el) => el.conversationNumber === conversationNumber).isScheduled;

    // check
    if (newValue) {
      // remove the changed value from the highlighted list
      changedConversationNumbers.value = changedConversationNumbers.value.filter((el) => el !== conversationNumber);

      model.dates
        .filter((el) => el.conversationNumber <= conversationNumber)
        .forEach((el) => {
          el.isScheduled = true;
        });
    }
    // uncheck
    else {
      model.dates
        .filter((el) => el.conversationNumber >= conversationNumber)
        .forEach((el) => {
          el.isScheduled = false;

          // remove the changed value from the highlighted list
          changedConversationNumbers.value = changedConversationNumbers.value.filter((el2) => el2 !== el.conversationNumber);
        });
    }

    // remove message if no rows are highlighted
    if (!changedConversationNumbers.value.length) {
      showChangeMessage.value = false;
    }
  };

  const getPreviousDateTime = (conversationNumber: any) => {
    const previousDate = model.dates.find((el) => el.conversationNumber === conversationNumber - 1);
    if (!previousDate) {
      return getTime(utcToZonedTime(new Date(), memberTimeZone)); // convert to member tz for comparison
    } else {
      return previousDate.scheduledDateTime;
    }
  };

  const model = reactive({
    dates: [],
  });

  const updateScheduledDateTime = (conversationNumber: number, value: any) => {
    // remove the changed value from the highlighted list
    changedConversationNumbers.value = changedConversationNumbers.value.filter((el) => el !== conversationNumber);

    // get correct conversation
    const conversation = model.dates.find((el) => el.conversationNumber === conversationNumber);

    // update member & partner
    conversation.scheduledDateTime = value;
    conversation.partnerDateTime = utcToZonedTime(zonedTimeToUtc(new Date(value), memberTimeZone), partnerTimeZone);

    // update later conversation dates (if needed)
    model.dates.forEach((el) => {
      // ignore previous conversations
      if (el.conversationNumber <= conversationNumber) {
        return;
      }

      const previousConversation = model.dates.find((el2) => el2.conversationNumber === el.conversationNumber - 1);
      const nextConversation = model.dates.find((el2) => el2.conversationNumber === el.conversationNumber + 1);

      // move conversation forward
      if (add(previousConversation.scheduledDateTime, { hours: 1 }) > el.scheduledDateTime && el.isScheduled) {
        showChangeMessage.value = true;
        changedConversationNumbers.value.push(el.conversationNumber);

        if (nextConversation) {
          el.scheduledDateTime = nextConversation.scheduledDateTime;
          el.partnerDateTime = nextConversation.partnerDateTime;
        } else {
          el.scheduledDateTime = getTime(add(previousConversation.scheduledDateTime, { weeks: 2 }));
          el.partnerDateTime = getTime(add(previousConversation.partnerDateTime, { weeks: 2 }));
        }
      }
      // if not selected, increment
      else if (!el.isScheduled) {
        if (nextConversation) {
          el.scheduledDateTime = nextConversation.scheduledDateTime;
          el.partnerDateTime = nextConversation.partnerDateTime;
        } else {
          el.scheduledDateTime = getTime(add(previousConversation.scheduledDateTime, { weeks: 2 }));
          el.partnerDateTime = getTime(add(previousConversation.partnerDateTime, { weeks: 2 }));
        }
      }
    });

    // remove message if no rows are highlighted
    if (!changedConversationNumbers.value.length) {
      showChangeMessage.value = false;
    }
  };

  const restore = () => {
    initDates();
    closeChangeMessage();
  };

  const closeChangeMessage = () => {
    showChangeMessage.value = false;
    changedConversationNumbers.value = [];
  };

  const canContinue = computed(() => {
    // don't continue if any dates are in the past
    var pastDates = model.dates.filter((el) => el.isScheduled && isPast(el.scheduledDateTime));
    if (pastDates && pastDates.length) {
      return false;
    }

    return true;
  });

  const advance = async () => {
    const conversations = model.dates.map((el) => ({
      peerConversationId: el.peerConversationId,
      isScheduled: el.isScheduled,
      scheduledDateTime: zonedTimeToUtc(el.scheduledDateTime, el.scheduledTimeZone),
      scheduledTimeZone: el.scheduledTimeZone,
    }));
    emits('advance', { conversations });
  };
</script>

