import { App } from "vue";
import { formatNumber, formatDate, formatPercent } from '@/common/utils';
import { pluralize } from '@/common/utils';

const registerFilters = (app: App<Element>) => {
  app.config.globalProperties.$filters = {
    formatNumber,
    formatDate,
    formatPercent,
    pluralize
  }
};

export default registerFilters;
