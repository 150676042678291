import { App } from 'vue';
import * as Sentry from '@sentry/vue';
import posthog from 'posthog-js';

import { router } from '@/router';
import { Integration } from '@sentry/types/types/integration';

export function setupSentry(app: App) {
  //if (import.meta.env.PROD) {
  Sentry.init({
    enabled: import.meta.env.VITE_SENTRY_ENABLED === 'true',
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    // tunnel: '/sentry',
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    integrations: [
      new posthog.SentryIntegration(posthog, 'imperative', 17333, 'pcpv2') as Integration,
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    trackComponents: true,
    tracesSampleRate: import.meta.env.PROD ? 0.8 : 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tunnel: 'https://sentrytunnel.imperative.com/tunnel',
    attachStacktrace: true,
    autoSessionTracking: true,
  });

  app.config.globalProperties.$sentry = Sentry;

  return Sentry;
  // }
}

export default Sentry;
