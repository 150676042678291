import { useDeviceSettingsStore } from '@/components/DeviceSettings/DeviceSettingsStore';
import { defineStore, acceptHMRUpdate } from 'pinia';
import { getDeviceInfo, isPermissionDenied } from '@/utils';
import { useToast } from 'vue-toastification';

import * as TwilioVideo from 'twilio-video';

import * as TwilioVideoProcessors from '@twilio/video-processors';
import { getUserFriendlyError } from '@/utils/videoErrors';

type TrackType = TwilioVideo.LocalAudioTrack | TwilioVideo.LocalVideoTrack | TwilioVideo.RemoteAudioTrack | TwilioVideo.RemoteVideoTrack | undefined;

type LocalTrackType = TwilioVideo.LocalAudioTrack | TwilioVideo.LocalVideoTrack | undefined;
type RemoteTrackType = TwilioVideo.RemoteAudioTrack | TwilioVideo.RemoteVideoTrack | undefined;
import { posthog } from '@/plugins/posthog';
const toast = useToast();

export enum ConnectionState {
  Disconnected = 'Disconnected',
  Waiting = 'Waiting',
  Connected = 'Connected',
  Error = 'Error',
  Reconnecting = 'Reconnecting',
  Reconnected = 'Reconnected',
}

interface State {
  room: TwilioVideo.Room | null;
  connectionState: ConnectionState;
  remoteConnectionState: ConnectionState;

  localMediaContainer: HTMLElement;
  remoteMediaContainer: HTMLElement;

  localTracks: TwilioVideo.LocalTrack[];
  remoteTracks: TwilioVideo.RemoteTrack[];

  localVideo: TwilioVideo.LocalVideoTrack | null;
  localAudio: TwilioVideo.LocalAudioTrack | null;

  peerConversationId: string;
  error: TwilioErrors | null;

  remoteVideoEnabled: boolean;
  remoteAudioEnabled: boolean;

  localVideoEnabled: boolean;
  localAudioEnabled: boolean;

  remoteNetworkQuality: number;
  localNetworkQuality: number;

  twilioSupported: boolean;
  blurSupported: boolean;
}

enum TwilioErrors {
  ConnectionLost = 'ConnectionLost',
  DeviceConnectionLost = 'DeviceConnectionLost',
  PartnersConnectionLost = 'PartnersConnectionLost',
  TokenExpired = 'TokenExpired',
  ReconnectTimeout = 'ReconnectTimeout',
  ReconnectTooManyTimes = 'ReconnectTooManyTimes',
  RoomConnectionError = 'RoomConnectionError',
}

const mediaErrors = ['NotAllowedError', 'NotFoundError', 'NotReadableError', 'OverconstrainedError', 'TypeError'];

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

function handleMediaError(error) {
  console.error(error);
  toast.error(getUserFriendlyError(error), { timeout: 10000 });
}

const blurBackgroundProcessor = TwilioVideoProcessors.isSupported
  ? new TwilioVideoProcessors.GaussianBlurBackgroundProcessor({
      assetsPath: '/twilio',
      debounce: isSafari,
      blurFilterRadius: 15,
      maskBlurRadius: 5,
      pipeline: TwilioVideoProcessors.Pipeline.WebGL2,
    })
  : null;

export const useVideoStore = defineStore({
  id: 'video',
  state: (): State => ({
    room: null,
    connectionState: ConnectionState.Waiting,
    remoteConnectionState: ConnectionState.Disconnected,
    localTracks: [],
    remoteTracks: [],

    localMediaContainer: null,
    remoteMediaContainer: null,

    localVideo: null,
    localAudio: null,

    peerConversationId: null,
    error: null,

    remoteVideoEnabled: true,
    remoteAudioEnabled: true,

    localVideoEnabled: true,
    localAudioEnabled: true,

    localNetworkQuality: 0,
    remoteNetworkQuality: 0,

    twilioSupported: TwilioVideo.isSupported,
    blurSupported: TwilioVideoProcessors.isSupported,
  }),
  actions: {
    async init(peerConversationId: string, token: string, localMediaContainer: HTMLElement, remoteMediaContainer: HTMLElement) {
      try {
        this.peerConversationId = peerConversationId;
        this.localMediaContainer = localMediaContainer;
        this.remoteMediaContainer = remoteMediaContainer;

        // set device lists
        const deviceSettingsStore = useDeviceSettingsStore();

        if (!deviceSettingsStore.isSupported) {
          // toast.error('Device settings are not supported');
          throw new Error('Device settings are not supported');
        }

        // start local participant tracks and connect local participant to room
        await this.startLocalTracks();

        await this.connectToRoom(token, peerConversationId);

        // // room has been joined
        await this.roomJoined();
      } catch (err) {
        handleMediaError(err);
      }
    },

    // connect member to room
    async connectToRoom(token: string, peerConversationId: string) {
      try {
        this.connectionState = ConnectionState.Waiting;

        const room = await TwilioVideo.connect(token, {
          name: peerConversationId,
          tracks: this.localTracks,
          networkQuality: {
            local: 1,
            remote: 2,
          },
          audio: true,
          video: true,
        });

        room.localParticipant.setNetworkQualityConfiguration({
          local: 2,
          remote: 1,
        });

        this.room = markRaw(room);
      } catch (err) {
        if (mediaErrors.includes(err.name)) {
          handleMediaError(err);
        } else {
          console.error(err);
        }
      }
    },

    async roomJoined() {
      // handle local participant connected
      this.room.localParticipant.on('networkQualityLevelChanged', (q: number) => (this.localNetworkQuality = q));

      // set audio out device
      const deviceSettingsStore = useDeviceSettingsStore();
      await this.changeAudioOutput(deviceSettingsStore.audioOutputDevice?.deviceId);

      // handle remote participant connected
      this.room.participants.forEach((el) => this.participantConnected(el));
      this.room.on('participantConnected', this.participantConnected);

      // remote participant disconnected
      this.room.on('participantDisconnected', this.participantDisconnected);

      this.room.on('participantReconnected', (participant: TwilioVideo.RemoteParticipant) => {
        this.remoteConnectionState = ConnectionState.Reconnected;

        window.setTimeout(() => {
          this.remoteConnectionState = ConnectionState.Connected;
        }, 2000);
      });

      this.room.on('participantReconnecting', (participant: TwilioVideo.RemoteParticipant) => {
        this.remoteConnectionState = ConnectionState.Reconnecting;
      });

      // local participant disconnected
      this.room.on('disconnected', (r: TwilioVideo.Room, err: TwilioVideo.TwilioError) => {
        if (err) {
          if (err.code == 20104) {
            this.error = TwilioErrors.TokenExpired;
          } else if (err.code == 53000) {
            this.error = TwilioErrors.ReconnectTooManyTimes;
          } else if (err.code == 53204) {
            this.error = TwilioErrors.ReconnectTimeout;
          }
        }

        this.stopLocalTracks();

        r.participants.forEach(this.participantDisconnected);

        this.connectionState = ConnectionState.Disconnected;
      });
    },

    async startLocalTracks() {
      try {
        const deviceSettingsStore = useDeviceSettingsStore();

        const isCameraPermissionDenied = await isPermissionDenied('camera');
        const isMicrophonePermissionDenied = await isPermissionDenied('microphone');

        const deviceInfo = await getDeviceInfo();

        const shouldAcquireVideo = !isCameraPermissionDenied && deviceInfo.hasVideoInputDevices;
        const shouldAcquireAudio = !isMicrophonePermissionDenied && deviceInfo.hasAudioInputDevices;

        // create local tracks
        const localTracks = await TwilioVideo.createLocalTracks({
          video: shouldAcquireVideo && {
            name: `camera-${Date.now()}`,
            // ...(deviceSettingsStore.hasDefaultVideoDevice && { deviceId: { exact: deviceSettingsStore.videoInputDevice.deviceId! } }),
          },
          audio:
            shouldAcquireAudio &&
            {
              // ...(deviceSettingsStore.hasDefaultAudioDevice && { deviceId: { exact: deviceSettingsStore.audioInputDevice.deviceId! } }),
            },
        });

        this.localTracks = markRaw(localTracks);

        this.localTracks.forEach((track: TwilioVideo.LocalTrack) => {
          track.on('disabled', () => {
            if (track.kind === 'video') {
              this.localVideoEnabled = track.isEnabled;
            }
          });

          track.on('enabled', () => {
            if (track.kind === 'video') {
              this.localVideoEnabled = track.isEnabled;
            }
          });
        });

        await this.blurBackgroundAll(deviceSettingsStore.blurBackground);

        this.attachTracks(this.localTracks, this.localMediaContainer);
      } catch (err) {
        handleMediaError(err);
      }
    },

    stopLocalTracks() {
      this.room?.localParticipant?.unpublishTracks(this.localTracks);
      this.detachTracks(this.localTracks);
      this.stopTracks(this.localTracks);
    },

    stopTracks(tracks: LocalTrackType[]) {
      tracks.forEach((track: LocalTrackType) => track && track.stop());
    },

    toggleVideo() {
      this.localTracks.forEach((track: LocalTrackType) => {
        if (track.kind !== 'video') return;
        this.toggleTrack(track);

        this.localVideoEnabled = track.isEnabled;
      });
    },

    toggleAudio() {
      this.localTracks.forEach((track: LocalTrackType) => {
        if (track.kind !== 'audio') return;
        this.toggleTrack(track);
        this.localAudioEnabled = track.isEnabled;
      });
    },

    toggleTrack(track: LocalTrackType) {
      if (track.isEnabled) {
        track.disable();
      } else {
        track.enable();
      }
    },

    async changeAudioInput(deviceId: string) {
     if(!this.room) {
          posthog.capture('changeAudioInput - room was null', {
            deviceId: deviceId,
            conversationId: this?.peerConversationId,
          } );
       toast.error('Unable to change audio input device at this time.');
       return;
     }
      const localParticipant = this.room.localParticipant;

      if (deviceId && localParticipant) {
        // console.log('change audio input');

        TwilioVideo.createLocalAudioTrack({
          deviceId: { exact: deviceId },
        })
          .then((localAudioTrack) => {
            const tracks = Array.from(localParticipant.audioTracks.values()).map((publication: { track: TwilioVideo.LocalAudioTrack }) => publication.track);

            localParticipant.unpublishTracks(tracks);

            this.detachTracks(tracks);
            this.stopTracks(tracks);

            localParticipant.publishTrack(localAudioTrack);
            this.attachTracks([localAudioTrack], this.localMediaContainer);

            const newTracks = this.localTracks.filter((el) => el.kind !== 'audio');
            newTracks.push(localAudioTrack);
            this.localTracks = markRaw(newTracks);
          })
          .catch(handleMediaError);
      }
    },

    async changeAudioOutput(deviceId: string) {

      if(!this.room) {
        posthog.capture('changeAudioOutput - room was null', {
          deviceId: deviceId,
          conversationId: this?.peerConversationId,
        } );
        toast.error('Unable to change audio output device at this time.');
        return;
      }
      const localParticipant = this.room.localParticipant;

      if (deviceId && localParticipant) {
        // console.log('change audio output');
        this.remoteTracks.forEach(async (el: RemoteTrackType) => {
          if (el.kind === 'audio') {
            const audioElement = el.attach();

            // @ts-ignore
            const setSinkId = audioElement.setSinkId ? audioElement.setSinkId.bind(audioElement) : null;

            if (setSinkId) {
              await setSinkId(deviceId);
            }
          }
        });
      }
    },

    async changeVideoInput(deviceId: string) {
      try {
        if (!this.room) {
          return;
        }

        const localParticipant = this.room.localParticipant;

        if (deviceId && localParticipant) {
          // console.log('change video input');

          const localVideoTrack = await TwilioVideo.createLocalVideoTrack({
            deviceId: { exact: deviceId },
            name: `camera-${Date.now()}`,
          });

          const tracks = Array.from(localParticipant.videoTracks.values()).map((publication: { track: TwilioVideo.LocalVideoTrack }) => publication.track);

          localParticipant.unpublishTracks(tracks);
          this.detachTracks(tracks);
          this.stopTracks(tracks);

          localParticipant.publishTrack(localVideoTrack);
          this.attachTracks([localVideoTrack], this.localMediaContainer);

          const newTracks = this.localTracks.filter((el) => el.kind !== 'video');
          newTracks.push(localVideoTrack);
          this.localTracks = markRaw(newTracks);

          // set blur
          const deviceSettingsStore = useDeviceSettingsStore();
          await this.blurBackgroundAll(deviceSettingsStore.blurBackground);
        }
      } catch (err) {
        handleMediaError(err);
      }
    },

    async blurBackgroundAll(blurred: boolean) {
      const localVideoTrack = this.localTracks.find((el) => el.kind === 'video');
      await this.blurBackground(localVideoTrack, blurred);
    },

    async blurBackground(track: TwilioVideo.LocalVideoTrack, blurred: boolean) {
      if (!this.blurSupported) {
        return;
      }

      if (!blurBackgroundProcessor || !track) {
        return;
      }

      if (blurred && !track.processor) {
        try {
          await blurBackgroundProcessor.loadModel();
          track.addProcessor(blurBackgroundProcessor, {
            inputFrameBufferType: 'video',
            outputFrameBufferContextType: 'webgl2',
          });
        } catch (err: any) {
          console.error('processor error:', err);
          return Promise.reject(err);
        }
      } else if (!blurred && !!track.processor) {
        track.removeProcessor(blurBackgroundProcessor);
      }
    },

    attachTracks(tracks: TwilioVideo.LocalVideoTrack[], container: HTMLElement) {
      tracks.forEach((track: TwilioVideo.LocalVideoTrack) => container.appendChild(track.attach()));
      return tracks;
    },

    detachTracks(tracks: TwilioVideo.LocalVideoTrack[]) {
      tracks.forEach((track) => {
        if (track) {
          track.detach().forEach((detachedElement: HTMLElement) => detachedElement.remove());
        }
      });
    },

    disconnect() {
      this.stopLocalTracks();
      // const deviceSettingsStore = useDeviceSettingsStore();
      // deviceSettingsStore.unmountDevices();

      this.room?.disconnect();
      this.room = null;
    },

    // subscribe to participant's tracks
    participantConnected(participant: TwilioVideo.RemoteParticipant) {
      this.remoteTracks = markRaw([]);

      participant.on('networkQualityLevelChanged', (q: number) => (this.remoteNetworkQuality = q));

      // iterate through the participant's published tracks and call `handleTrackPublication` on them
      participant.tracks.forEach((trackPublication, key) => {
        this.handleTrackPublication(trackPublication, participant);

        trackPublication.on('trackDisabled', () => {
          if (trackPublication.track.kind === 'video') {
            this.remoteVideoEnabled = (trackPublication.track as TwilioVideo.RemoteVideoTrack).isEnabled;
          }

          if (trackPublication.track.kind === 'audio') {
            this.remoteAudioEnabled = (trackPublication.track as TwilioVideo.RemoteAudioTrack).isEnabled;
          }
        });

        trackPublication.on('trackEnabled', () => {
          if (trackPublication.track.kind === 'video') {
            this.remoteVideoEnabled = (trackPublication.track as TwilioVideo.RemoteVideoTrack).isEnabled;
          }

          if (trackPublication.track.kind === 'audio') {
            this.remoteAudioEnabled = (trackPublication.track as TwilioVideo.RemoteAudioTrack).isEnabled;
          }
        });

        this.connectionState = ConnectionState.Connected;
      });

      participant.on('trackSubscribed', async (track: TwilioVideo.RemoteTrack) => {
        try {
          // console.log('trackSubscribed', track);

          this.remoteTracks.push(this.attachTracks([track], this.remoteMediaContainer));

          // attach to correct audio output
          if (track.kind === 'audio') {
            const deviceSettingsStore = useDeviceSettingsStore();
            const audioElement = track.attach();

            // @ts-ignore
            const setSinkId = audioElement.setSinkId ? audioElement.setSinkId.bind(audioElement) : null;

            // console.log('deviceSettingsStore.audioOutputDevice?.deviceId', deviceSettingsStore.audioOutputDevice?.deviceId);

            if (setSinkId && deviceSettingsStore.audioOutputDevice?.deviceId) {
              await setSinkId(deviceSettingsStore.audioOutputDevice?.deviceId);
            }
          }
        } catch (error) {
          console.error('trackSubscribed', { error });
        }
      });

      participant.on('trackUnsubscribed', (track: TwilioVideo.RemoteTrack) => this.detachTracks([track]));

      participant.on('disconnected', (participant: TwilioVideo.RemoteParticipant) => {});

      // listen for any new track publications
      // participant.on('trackPublished', this.handleTrackPublication);
    },

    handleTrackPublication(trackPublication, participant) {
      if (trackPublication.track) {
        this.remoteTracks.push(this.attachTracks([trackPublication.track], this.remoteMediaContainer));
      }
    },

    // unsubscribe to participant's tracks
    participantDisconnected(participant) {
      this.remoteConnectionState = ConnectionState.Disconnected;

      this.detachTracks(Array.from(participant.tracks.values()).map((publication: { track: TwilioVideo.Track }) => publication.track));

      this.remoteTracks = [];

      this.connectionState = ConnectionState.Waiting;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useVideoStore, import.meta.hot));
}
