import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { SignalRService, HubCommandToken, HubEventToken } from '@/middleware/signalr';

import { useAuthStore } from '@/services/authenticationService';

import { defineStore, acceptHMRUpdate } from 'pinia';

export const useSignalRStore = defineStore({
  id: 'signalr',
  state: () => ({
    signalr: null as SignalRService | null,
  }),
  actions: {
    async connect(): Promise<SignalRService> {
      const authStore = useAuthStore();

      this.signalr = new SignalRService(
        {
          url: import.meta.env.VITE_PCP_HUB_BASE_URL + '/conversation-hub?pcid=' + window.location.pathname.replace('/', ''),
          accessTokenFactory: () => {
            const token = sessionStorage.getItem('token');
            return token;
          },
          automaticReconnect: true,
          prebuild(builder: HubConnectionBuilder) {
            builder.configureLogging(LogLevel.Information);
          },
          disconnected() {},
          reconnected() {},
        },
        new HubConnectionBuilder()
      );

      this.signalr.init();

      return this.signalr;
    },

    /**
     * Send a command to the SignalR hub
     * @param target The name or token of the command to send to
     * @param message The payload to send to the command
     * @returns a promise the resolves with the event returns a value
     */
    invoke<TMessage, TResponse>(target: HubCommandToken<TMessage, TResponse>, message?: TMessage): Promise<TResponse> {
      return this.signalr.invoke(target, message);
    },

    /**
     * Send a command to the SignalR hub without awaiting a response
     * @param target The name or token of the command to send to
     * @param message The payload to send to the command
     */
    send<T>(target: HubCommandToken<T>, message?: T) {
      this.signalr.send(target, message);
    },

    /**
     * Subscribe to an event on the hub
     * @param target The name or token of the event to listen to
     * @param callback The callback to trigger with the hub sends the event
     * @param autoUnsubscribe Override options.automaticUnsubscribe config
     */
    on<T>(target: HubEventToken<T>, callback: (arg: T) => void) {
      this.signalr.on(target, callback);
    },

    /**
     * Unsubscribe from an event on the hub
     * @param target The name or token of the event to unsubscribe from
     * @param callback The specific callback to unsubscribe. If none is provided, all listeners on the target will be unsubscribed
     */
    off<T>(target: HubEventToken<T>, callback?: (arg: T) => void) {
      this.signalr.off(target, callback);
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSignalRStore, import.meta.hot));
}

