<template>
  <div class="px-6 py-4" role="tooltip" >
    <p class="text-gray-500">
      <MarkdownViewer :source="t(currentTip.text, { memberName: myName, partnerName })" :breaks="true" class="prose max-w-none"></MarkdownViewer>
    </p>
  </div>
  <div class="m-4 text-center">
    <BaseButton color="primary" @click="next()"> {{ !lastTip ? t('next') : t('close') }} </BaseButton>
  </div>
  <base-exit-link class="mt-8" />
</template>

<script setup lang="ts">
  import { computed } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useMachine } from '@xstate/vue';
  import BaseButton from '@/components/BaseControls/BaseButton.vue';
  import { useIntroTipMachine } from './introTips.machine';
  import { useConductorStore } from '@/stores/conductorStore';

  const { t } = useI18n();

  // props
  interface Props {
    content?: any;
  }

  const props = withDefaults(defineProps<Props>(), {
    content: {},
  });

  // emits
  const emits = defineEmits<{
    (event: 'advance', payload: any): void;
  }>();

  const conductorStore = useConductorStore();

  const myName = computed(() => conductorStore.member.firstName);
  const partnerName = computed(() => conductorStore.partner.firstName);

  const currentTip = computed(() => {
    return props.content.introTips[state.value.context.currentIndex];
  });

  const lastTip = computed(() => {
    return state.value.context.currentIndex === state.value.context.total - 1;
  });

  const setTitle = () => {
    conductorStore.content.title = currentTip.value.title;
  };

  const next = async () => {
    if (!lastTip.value) {
      send('NEXT');
      setTitle();
    } else {
      emits('advance', {});
    }
  };

  var introTipMachine = useIntroTipMachine('introtip', props.content.introTips);

  const { state, send } = useMachine(introTipMachine);

  onMounted(() => {
    send('START');
    setTitle();
  });
</script>
