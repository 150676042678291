import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import createAuthRefreshInterceptor, { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh';
import { useAuthStore } from '@/services/authenticationService';
import { router } from '@/router';
import { RouteRecordRaw } from 'vue-router';
import { useToast } from 'vue-toastification';
import axiosAuthCore from './axios-auth-core';

axios.defaults.withCredentials = true;

function getAccessToken() {
  const token = sessionStorage.getItem('token');
  return token;
}

const axiosCore = axios.create({
  baseURL: import.meta.env.VITE_PCP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
} as AxiosRequestConfig);

// request interceptor
axiosCore.interceptors.request.use((request) => {
  request.headers.Authorization = `Bearer ${getAccessToken()}`;
  return request;
});

// refresh token
const refreshAuthLogic = async (failedRequest: AxiosError) => {
  if (failedRequest && failedRequest.response && failedRequest.response.status === 401) {
    try {
      const tokenResponse = await axiosAuthCore.post('/auth/refresh-token', null, { skipAuthRefresh: true } as AxiosAuthRefreshRequestConfig);

      if (tokenResponse.data) {
        const { token } = tokenResponse.data.data;
        sessionStorage.setItem('token', tokenResponse.data.data.token);
        failedRequest.response.config.headers.Authorization = 'Bearer ' + token;
      } else {
        await useAuthStore().signOut();
        return router.push({ name: 'SignOut' } as RouteRecordRaw);
      }
    } catch (error: any) {
      return Promise.reject(error);
    }
  } else {
    sessionStorage.removeItem('token');
  }
};

createAuthRefreshInterceptor(axiosCore, refreshAuthLogic, {
  statusCodes: [401],
  pauseInstanceWhileRefreshing: false, // According to docs, `false` is default value, but in fact it's not :-)
});

const toast = useToast();

// response interceptor
axiosCore.interceptors.response.use(
  (response) => response,
  async (error) => {
    // do something with response error
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error(error);
      //console.log(error.response.data);
      //console.log(error.response.status);
      //console.log(error.response.headers);

      if (error.response.status === 422) {
        return Promise.reject({
          httpStatus: 422,
          validations: error.response.data.validations,
        });
      } else if (error.response.status === 404) {
        // console.log('404 error');
        toast.error('Requested resource cannot be found');
        return Promise.reject({ httpStatus: 404 });
      } else if (error.response.status === 403) {
        // console.log('403 error');
        toast.error(`You have not been authenticated`);
        return Promise.reject({ httpStatus: 403 });
      } else if (error.response.status === 401) {
        // console.log('401 error');
        toast.error(`You have not been authorized to perform this action`);
        return Promise.reject({ httpStatus: 401 });
      } else {
        // console.log('unknown error');
        toast.error('There was an error with the response: ' + error.response.status);
        return Promise.reject({ httpStatus: error.response.status });
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      toast.error('The request was made but no response was received');
      console.error(error.request);
      //return Promise.reject(error);
    } else {
      // Something happened in setting up the request that triggered an Error
      toast.error('There was an error setting up the request');
      console.error('Error: ', error.message);
      //return Promise.reject(error);
    }

    // default reject
    return Promise.reject(error);

    // const request = error.config;

    // if ((request as AxiosAuthRefreshRequestConfig) && (request as AxiosAuthRefreshRequestConfig).skipAuthRefresh) {
    //   location.href = '/sign-out';
    //   return Promise.reject(error);
    // }

    // if (error && error.response && error.response.status === 401) {
    //   // let's retry
    //   return await axiosCore(request);
    // }

    // throw error;
  }
);

export default axiosCore;
