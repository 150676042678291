import { createMachine, assign, MachineConfig, Machine } from 'xstate';

export interface IntroTip {
  text: string;
  title: string;
}

export interface IntroTipContext {
  modalOpen: boolean;
  tips: IntroTip[];
  total: number;
  currentIndex: number;
}

export const useIntroTipMachine = (id: string, tips: IntroTip[]) => {
  const baseConfig = {
    id: id,
    initial: 'init',
    context: {
      modalOpen: false,
      tips: tips,
      total: tips.length,
      currentIndex: 0,
    },
    states: {
      init: {
        on: {
          START: {
            actions: assign({ modalOpen: true }),
            target: `tip-0`
          }
        },
      },
      done: {
        entry: assign({ modalOpen: false }),
        type: 'final',
      },
    },
  };

  const machine = createMachine(
    tips.reduce((config, tip, index) => {

      const tipId = `tip-${index}`;

      // console.log('tipId', tipId, config);

      config.states[tipId] = {
        entry: assign({ currentIndex: index }),
        meta: {
          title: config.context.tips[index].title,
          descr: config.context.tips[index].text,
        },
        on: {
          NEXT: {
            actions: assign({ currentIndex: context => context.currentIndex + 1 }),
            target: index + 1 === tips.length ? 'done' : `tip-${index + 1}`,
          },
        },
      };

      return config;

    }, baseConfig) as MachineConfig<IntroTipContext, any, any>
  );

  return machine;
};