import { parseISO, format } from 'date-fns';

export default function formatDate(
  date: Date | string,
  formatString: string = 'MMM dd, yyyy',
  defaultVal: string = ''): string {

  if (date == null || typeof date === 'undefined') {
    return defaultVal;
  }
  if (typeof date === 'string') {
    date = parseISO(date);
  }
  return format(date, formatString);
}
