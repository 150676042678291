<template>
  <div class="mt-2 sm:mt-4">
    <TabGroup vertical class="flex" as="div" v-slot="{ selectedIndex }" :selected-index="selectedTab" @change="onTabChange">
      <TabList class="flex flex-col justify-start flex-shrink-0 w-28">
        <Tab
          v-for="(tab, idx) in tabs"
          :key="idx"
          :data-testid="'tab-' + idx"
          v-slot="{ selected }"
          as="template"
          :ref="
            (el) => {
              items[idx] = el;
            }
          ">
          <button
            :class="[
              // flex h-24 flex-col items-center rounded-l-md bg-gray-100 py-4 text-pink-500
              'flex flex-col items-center whitespace-nowrap rounded-l-md py-4 text-sm font-medium leading-5',
              'focus:outline-none ',
              selected ? 'bg-gray-100 text-pink-500' : 'bg-white',
            ]">
            <font-awesome-icon :icon="['fad', tab.icon]" class="flex-shrink-0 w-6 h-6"></font-awesome-icon>
            <!-- <div v-if="active && idx == 0" :id="idx + '-tab-description'" class="sr-only">{{ tab.title + ' the following question. ' + tab.content }}</div>
            <div v-else-if="!active && idx == 0" :id="idx + '-tab-description'" class="sr-only">{{
              myName + ', answer the following question. ' + tab.content
            }}</div>
            <div v-else :id="idx + '-tab-description'" class="sr-only">{{ tab.title + '. ' + tab.content }}</div>
            <div :aria-describedby="idx + '-tab-description'" class="px-4 mt-2 text-sm font-semibold">{{ t(tab.name) }}</div> -->
          </button>
        </Tab>
      </TabList>
      <TabPanels
        class="flex-grow p-4 overflow-hidden bg-gray-100 rounded-r-md"
        :class="selectedIndex == 0 ? 'rounded-bl-md' : selectedIndex == tabs.length - 1 ? 'rounded-tl-md' : 'rounded-bl-md rounded-tl-md'">
        <TabPanel v-for="(tab, idx) in tabs" :key="idx">
          <h2 class="mb-2 text-base font-medium text-pink-500 md:text-xl">
            {{ tab.title }}
          </h2>
          <div class="overflow-y-auto max-h-60">
            <MarkdownViewer class="prose-sm prose text-gray-800 md:prose-base" :source="tab.content" breaks :class="tab.customClass"></MarkdownViewer>
          </div>
        </TabPanel>
      </TabPanels>
    </TabGroup>
    <!-- ACTIVE -->
    <div v-if="editor != null && props.active" class="px-2 sm:px-0" id="active-panel">
      <div class="mt-4 border border-gray-300 rounded" id="triggerDiv" ref="firstTimeTrigger">
        <editor-content :editor="editor" class="max-h-48 min-h-[10rem] overflow-y-auto border-b border-b-gray-100"> </editor-content>
        <!-- TOOLBAR -->
        <div class="flex items-center justify-start px-4 py-3 text-gray-300 gap-x-2">
          <button
            :aria-label="t('textEditor.labelBold')"
            class="p-1 hover:text-gray-700"
            @click="editor.chain().focus().toggleBold().run()"
            :class="{ 'text-gray-500': editor.isActive('bold') }">
            <font-awesome-icon icon="bold" class="w-5 h-5" />
          </button>
          <button
            :aria-label="t('textEditor.labelItalic')"
            class="p-1 hover:text-gray-700"
            @click="editor.chain().focus().toggleItalic().run()"
            :class="{ 'text-gray-500': editor.isActive('italic') }">
            <font-awesome-icon icon="italic" class="w-5 h-5" />
          </button>
          <button
            :aria-label="t('textEditor.labelBulletList')"
            class="p-1 hover:text-gray-700"
            @click="editor.chain().focus().toggleBulletList().run()"
            :class="{ 'text-gray-500': editor.isActive('bulletList') }">
            <font-awesome-icon icon="list" class="w-5 h-5" />
          </button>
          <button
            :aria-label="t('textEditor.labelOrderedList')"
            class="p-1 hover:text-gray-700"
            @click="editor.chain().focus().toggleOrderedList().run()"
            :class="{ 'text-gray-500': editor.isActive('orderedList') }">
            <font-awesome-icon icon="list-ol" class="w-5 h-5" />
          </button>
          <button
            :aria-label="t('textEditor.labelHeading')"
            class="p-1 hover:text-gray-700"
            @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
            :class="{ 'text-gray-500': editor.isActive('heading', { level: 1 }) }">
            <font-awesome-icon icon="heading" class="w-5 h-5" />
          </button>
          <button
            :aria-label="t('textEditor.labelBlockQuote')"
            class="p-1 hover:text-gray-700"
            @click="editor.chain().focus().toggleBlockquote().run()"
            :class="{ 'text-gray-500': editor.isActive('blockquote') }">
            <font-awesome-icon icon="quote-right" class="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
    <!-- INACTIVE -->
    <div v-else-if="!props.active" id="passive-panel" class="flex justify-center pt-6 mt-8 text-base text-gray-500 border-t border-gray-200">

      <div
        >{{
          t('inAWord.inactive', {
            partnerName: active ? myName : partnerName,
          })
        }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { onMounted, onBeforeUnmount, nextTick, watch, computed } from 'vue';

  import { useI18n } from 'vue-i18n';

  import { Editor, EditorContent } from '@tiptap/vue-3';
  import StarterKit from '@tiptap/starter-kit';
  import Placeholder from '@tiptap/extension-placeholder';
  import MarkdownViewer from '@/components/MarkdownViewer/MarkdownViewer.vue';
  import { useConductorStore } from '@/stores/conductorStore';
  import { useFirstTimeTipsStore } from '@/stores/firstTimeTipsStore';
  import { Markdown } from 'tiptap-markdown';
  import CharacterCount from '@tiptap/extension-character-count';

  const { t } = useI18n();

  const props = defineProps({
    active: Boolean,
    content: Object as any,
    modelValue: String,
  });

  const emits = defineEmits(['update:modelValue']);
  const maxCharacterCount = 3000;
  const conductorStore = useConductorStore();
  const firstTimeTipsStore = useFirstTimeTipsStore();
  const myName = computed(() => conductorStore.member.firstName);
  const partnerName = computed(() => conductorStore.partner.firstName);
  let firstTimeTip = null;
  const selectedTab = ref(0);
  // first time tooltip references
  const items = ref([]);
  // first time tip reference trigger
  const firstTimeTrigger = ref();
  const showFirstTimeTipFollowUpTip = computed(
    () => conductorStore.isActive && firstTimeTipsStore.isFirstQuestion && conductorStore.member.finishedConversationCount === 0
  );
  const showFirstTimeNoteTip = computed(
    () => conductorStore.isActive && firstTimeTipsStore.isFirstQuestion && conductorStore.member.finishedConversationCount === 1
  );
  const showFirstTimeTipShareBack = computed(
    () => conductorStore.isActive && firstTimeTipsStore.isFirstQuestion && conductorStore.member.finishedConversationCount === 2
  );

  const onTabChange = (index) => {
    selectedTab.value = index;
  };

  const tabs = computed(() => {
    const list = [];
    selectedTab.value = 0;

    if (!props.content) {
      return list;
    }

    const firstVersion = conductorStore.finishedMembers.length === 0;

    list.push({
      name: 'questions.tabQuestion',
      icon: 'comment',
      content: firstVersion ? props.content.question : props.content.shortQuestion,
      title: props.active ? 'Ask ' + conductorStore.partner.firstName : '',
    });

    if (props.content.profile) {
      // get three profile tips
      const BREAK_LINE = '\n';
      const BULLETS_TO_PICK = 3;

      let profileTips = props.content.profile;

      if (props.content.profile.indexOf(BREAK_LINE) > 0) {
        const allBullets = props.content.profile.split(BREAK_LINE);

        const coacheeSlug = conductorStore.isActive ? conductorStore.partner.slug : conductorStore.member.slug;
        const memberHash = coacheeSlug.split('').reduce((a, b) => a + b.charCodeAt(0), 0);

        const shift = Math.max(memberHash % (allBullets.length - BULLETS_TO_PICK + 1), 0);
        const pickedBullets = allBullets.slice(shift, shift + BULLETS_TO_PICK);

        profileTips = pickedBullets.map((point) => (point.indexOf('- ') == 0 ? point : `- ${point}`)).join(BREAK_LINE);
      }

      if (props.content.profile.indexOf('.') > 0 && props.content.profile.indexOf(BREAK_LINE) <= 0) {
        const SENTENCE_DELIMETER = '. ';
        const allSentences = props.content.profile.split(SENTENCE_DELIMETER);
        // capitalize the first letter of each sentence
        const capitalizedSentences = allSentences.map((sentence) => sentence.charAt(0).toUpperCase() + sentence.slice(1));
        profileTips = capitalizedSentences.join(SENTENCE_DELIMETER);
      }

      let profileTitle = '';

      // classic usage of profile
      if (props.active) {
        profileTitle = 'From ' + conductorStore.partner.firstName + "'s Purpose Profile";
      } else {
        profileTitle = 'From My Purpose Profile';
      }

      // role based
      if (props.content.actionType === 'RoleBased') {
        // if mefirst we must be the mentor
        if (conductorStore.member.meFirst) {
          profileTitle = 'From ' + conductorStore.partner.firstName + "'s Purpose Profile";
        } else {
          // we must be the mentee
          profileTitle = 'From My Purpose Profile';
        }
      }

      list.push({
        name: 'questions.tabProfile',
        icon: 'user',
        content: profileTips,
        customClass: 'purpose-profile',
        title: profileTitle,
      });
    }

    if (props.content.followUp && props.active) {
      list.push({
        name: 'questions.tabFollowUp',
        icon: 'comment-dots',
        content: props.content.followUp,
        title: 'Follow-up',
      });
    }

    return list;
  });

  const editorTipContent = computed(() => {
    if (showFirstTimeTipShareBack.value) {
      return t('first_time_tool_tip.follow_up_question');
    }
    if (showFirstTimeNoteTip.value) {
      return t('first_time_tool_tip.coaching_tip_notes');
    }
    return '';
  });

  watch(
    () => props.modelValue,
    (value) => {
      // HTML
      const isSame = editor.value.storage.markdown.getMarkdown() === value;
      if (isSame) {
        return;
      }
      editor.value.commands.setContent(value, false);
    }
  );

  const editor = ref<Editor>(null);

  const loadToolTip = async () => {
    if (firstTimeTip) {
      return;
    }
    if (showFirstTimeTipFollowUpTip.value) {
      await nextTick();
      loadFirstTimeFollowUpTip();
    }

    if (showFirstTimeTipShareBack.value || showFirstTimeNoteTip.value) {
      await nextTick();
      loadQuestionTip();
      updateToolFirstTips();
    }
  };

  const loadFirstTimeFollowUpTip = () => {
    if (!tabs) {
      return;
    }

    const index = tabs.value.findIndex((el) => el.name === 'questions.tabFollowUp');
    if (index < 0) {
      return;
    }
    firstTimeTip = firstTimeTipsStore.getTippy(items.value[index].el, {
      content: t('first_time_tool_tip.follow_up_question'),
      placement: 'left',
      triggerTarget: firstTimeTrigger,
    });
  };

  const loadQuestionTip = () => {
    firstTimeTip = firstTimeTipsStore.getTippy(firstTimeTrigger.value, {
      content: editorTipContent.value,
      placement: 'left',
    });
  };

  watch(
    () => items.value,
    async () => {
      await loadToolTip();
      updateToolFirstTips();
    }
  );

  const updateToolFirstTips = () => {
    if (!firstTimeTip) {
      return;
    }
    if (showFirstTimeTipShareBack.value || showFirstTimeTipFollowUpTip.value || showFirstTimeNoteTip.value) {
      firstTimeTip.show();
    } else {
      firstTimeTip.hide();
    }
  };

  watch(
    () => showFirstTimeTipFollowUpTip.value,
    async () => {
      await loadToolTip();
      updateToolFirstTips();
    }
  );

  watch(
    () => showFirstTimeTipShareBack.value,
    async () => {
      await loadToolTip();
      updateToolFirstTips();
    }
  );

  watch(
    () => showFirstTimeNoteTip.value,
    async () => {
      await loadToolTip();
      updateToolFirstTips();
    }
  );

  onMounted(async () => {
    editor.value = new Editor({
      extensions: [
        Markdown,
        CharacterCount.configure({
          limit: maxCharacterCount,
        }),
        StarterKit.configure({
          history: false,
          code: false,
          strike: false,
          heading: { levels: [1] },
          bulletList: { HTMLAttributes: { class: 'bulletlist' } },
          orderedList: { HTMLAttributes: { class: 'orderedlist' } },
        }),
        Placeholder.configure({
          placeholder: t('textEditor.textPlaceholder', { partnerName: partnerName.value }),
        }),
      ],
      autofocus: true,
      editorProps: {
        attributes: {
          class: 'prose prose-sm md:prose-base m-0 p-5 focus:outline-none max-w-none max-h-48 min-h-[10rem]',
        },
      },
      content: props.modelValue,
      onUpdate: () => {
        // emit markdown
        emits('update:modelValue', editor.value.storage.markdown.getMarkdown());
      },
    });

    await loadToolTip();
    updateToolFirstTips();
  });

  onBeforeUnmount(() => {
    if (firstTimeTip?.value) {
      firstTimeTip.destroy();
      firstTimeTip = null;
    }

    if (editor.value) {
      editor.value.destroy();
      editor.value = null;
    }
  });
</script>

<style lang="scss">
  .ProseMirror p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
    height: 0;
  }

  .bulletlist,
  .orderedlist {
    list-style-type: none;
    li {
      margin: 0;
      p {
        margin: 0;
      }
    }
  }

  .purpose-profile {
    ul li {
      @apply first-letter:capitalize;
    }
  }

  .purpose-profile::first-letter {
    text-transform: capitalize;
  }
</style>

