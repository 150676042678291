<template>
  <div class="flex justify-start items-center mt-4 text-2xl font-bold">
    <span class="">{{ partnerName }}'s</span>
    <span>
      <img class="w-24 h-auto ml-2 mt-1.5"
           src="@/assets/images/imperative-logo.svg"
           alt="Imperative Logo"/></span>
  </div>
  <div class="mt-4">
    <form>
      <fieldset>
        <legend class="sr-only">
          {{
            t(getConversationActionText('imperative_focus_on'), {partnerName: partnerName, actionTag1:impacts[0], actionTag2:impacts[1], actionTag3:impacts[2]})
          }}
        </legend>
        <div class="font-medium tracking-wide italic" aria-hidden>
          {{
            t(getConversationActionText('imperative_focus_on'), {partnerName: partnerName, actionTag1:impacts[0], actionTag2:impacts[1], actionTag3:impacts[2]})
          }}
        </div>

        <div class="mt-4">
          <RadioGroup v-model="selectedImpactArea"

                      class="mt-1.5 flex flex-wrap place-content-stretch gap-3">
            <RadioGroupLabel class="sr-only" :aria-label="t('action_commitment.imperative_focus_on')"> Choose an Action Area</RadioGroupLabel>

            <RadioGroupOption as="template"
                              v-for="(impact, index)  in impacts"
                              :key="index"
                              :value="impact"
                              v-slot="{ active, checked }">
              <div
                  class="relative order-none flex h-auto w-auto flex-auto content-evenly items-center justify-center self-auto overflow-hidden rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
                <div
                    class="flex h-10 w-full cursor-pointer select-none items-center justify-center rounded-lg border border-gray-300 px-4 text-base font-medium shadow-sm"
                    :class="[active ? 'bg-primary-600' : '', checked ? 'bg-primary-600 border-transparent text-white hover:bg-primary-600' : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50'
                   ]">
                  <RadioGroupLabel as="span">{{ impact }}</RadioGroupLabel>
                </div>
              </div>
            </RadioGroupOption>

          </RadioGroup>
          <div v-if="selectedImpactArea"
               class="mt-4">
            <p class="font-semibold tracking-wide">{{ selectedImpactArea }}</p>
            <RadioGroup v-model="whyImperativeBeforeNextConv"
                        name="option"
                        class="mt-1.5 flex flex-wrap place-content-stretch gap-3"
                        aria-label="sub-categories of impact">
              <RadioGroupLabel class="sr-only" aria-label="sub-categories of impact"> Choose an action to take</RadioGroupLabel>

              <RadioGroupOption as="template"
                                v-for="option in imperativeOptions"
                                as:="span"
                                class="text-center"
                                :key="option.title"
                                :value="option"
                                v-slot="{ active, checked }">
                <div
                    class="relative order-none flex h-auto w-auto flex-auto content-evenly items-center justify-center self-auto overflow-hidden rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
                <span
                    :class="[active ? 'bg-primary-600' : '',
                      checked ? ' bg-primary-600 border-transparent text-white hover:bg-primary-600' : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
                        'flex h-10 w-full cursor-pointer select-none items-center justify-center rounded-lg border border-gray-300 px-4 text-base font-medium shadow-sm']">
                  <RadioGroupLabel as="span">{{ option.title }}</RadioGroupLabel>
                </span>
                </div>
              </RadioGroupOption>

            </RadioGroup>
            <div class="flex mt-4 w-full"
                 v-if="showOtherTextBox">

              <Field ref="otherTextInput"
                     maxlength="128"
                     type="text"
                     v-model="otherText"
                     name="other"
                     id="other"
                     aria-label="Add custom sub-category"
                     class="block border-gray-300 rounded-md shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm sm:w-full lg:w-2/3"
                     placeholder="Add other..."/>

            </div>
          </div>
          <div v-if="myImperativeAction" class="p-5 text-xs text-center text-gray-400 mt-2">
            {{
              t(getConversationActionText('avoid_sharing_options'))
            }}
          </div>
          <div v-else class="mt-4 text-gray-700">
            {{
              t(getConversationActionText('view_reflect_explanation'), {partnerName: partnerName})
            }}
          </div>
          <template v-if="(selectedImpactArea && whyImperativeBeforeNextConv)">
            <div class="space-y-4 mt-4">
              <div>
                <p class="font-medium tracking-wide italic">
                  {{
                    t(getConversationActionText('action_to_take_title'), {imperative: selectedImpactArea, whatToTakeActionOn: getImpactArea()})
                  }}
                </p>
                <div class="pt-2">
                  <Field type="text"
                         v-model="oneActionToTake"
                         id="oneActionToTake"
                         validate-on-input
                         data-testid="oneActionToTake"
                         name="oneActionToTake"
                         :aria-label="t('action_commitment.action_to_take_title', {imperative: selectedImpactArea, whatToTakeActionOn: getImpactArea()})"
                         maxlength="2000"
                         class="block w-full border-gray-300 rounded-md shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                         :placeholder="t(getConversationActionText('what_place_holder_text'), {partnerName})"/>
                </div>
              </div>
              <div>
                <p class="font-medium tracking-wide italic">{{ t(getConversationActionText('why_is_this_an_imperative')) }} </p>
                <div class="pt-2">
                  <Field type="text"
                         v-model="chosenSubInvestmentArea"
                         id="chosenSubInvestmentArea"
                         validate-on-input
                         data-testid="chosenSubInvestmentArea"
                         name="chosenSubInvestmentArea"
                         maxlength="2000"
                         :aria-label=" t('action_commitment.why_is_this_an_imperative') "
                         class="block w-full border-gray-300 rounded-md shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                         :placeholder="t(getConversationActionText('why_place_holder_text'), {partnerName})"/>
                </div>
              </div>
            </div>
          </template>
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script setup lang="ts">

import {RadioGroup, RadioGroupLabel, RadioGroupOption} from '@headlessui/vue'
import {watchThrottled} from '@vueuse/core'
import {useConductorStore} from "@/stores/conductorStore";
import {useI18n} from 'vue-i18n';
import {useActionCommitmentStore} from "@/stores/actionCommitmentStore";
import {useForm, Field, Form, validate} from "vee-validate";
import * as yup from 'yup';

const {t} = useI18n();
const conductorStore = useConductorStore();
const actionCommitmentStore = useActionCommitmentStore();

const myImperativeAction = ref(null);
const whyImperativeBeforeNextConv = ref(null);
const whyImperativeBeforeNextConvTranslate = ref(null);
const imperativeOptions = ref(null);
const showOtherTextBox = ref(false);
const otherText = ref(null);
const otherTextInput = ref(null);

const selectedImpactArea = ref(null);
const impacts = actionCommitmentStore.getImpactAreas;
const partnerName = computed(() => conductorStore.partner.firstName)

const chosenSubInvestmentArea = ref(null);
const oneActionToTake = ref(null);

const schema = yup.object({
  oneActionToTake: yup.string().min(2).required(),
  chosenSubInvestmentArea: yup.string().min(2).required()
});

const {meta} = useForm({
  validationSchema: schema,
  validateOnMount: true,
});
const isFieldsValid = computed(() => meta.value.valid);

const formValidEvent = defineEmits(['formValidEvent']);

watchThrottled([isFieldsValid, selectedImpactArea, chosenSubInvestmentArea, oneActionToTake, whyImperativeBeforeNextConv, otherText], () => {
  validateAndSend();
}, {throttle: 250});

const validateAndSend = async () => {
  let commitment = {};

  const isValid = isFieldsValid.value && isActionSelected() && isOtherProvided()

  if (isValid) {
    commitment = buildCommitment();
  }

  formValidEvent('formValidEvent', {isValid, commitment});
}
watch(selectedImpactArea, () => {

  resetActions();
  if (!selectedImpactArea?.value) {
    return;
  }
  imperativeOptions.value = actionCommitmentStore.getActionToImpact(selectedImpactArea.value);
  imperativeOptions.value.push({title: 'Other', value: 'Other'});
});

watch(whyImperativeBeforeNextConv, () => {
  if (!whyImperativeBeforeNextConv?.value) {
    resetActions()
    return;
  }
  showOtherTextBox.value = false;

  if (whyImperativeBeforeNextConv.value.title.includes('Other')) {
    showOtherTextBox.value = true;

    // set focus to the element after the DOM has been updated
    nextTick(() => {
      document.getElementById('other')?.focus();
    });

  } else {
    showOtherTextBox.value = null;
  }
});

const resetActions = () => {
  whyImperativeBeforeNextConv.value = null;
  whyImperativeBeforeNextConvTranslate.value = null;
  showOtherTextBox.value = false;
}

const buildCommitment = () => {

  const subInvestmentAreas = whyImperativeBeforeNextConv.value;
  const commitment = {
    selectedImpactArea: selectedImpactArea.value,
    chosenSubInvestmentArea: subInvestmentAreas.title,
    whatActionToTake: oneActionToTake.value,
    whyImperativeBeforeNextConv: chosenSubInvestmentArea.value,
    other: (subInvestmentAreas.title === 'Other') ? otherText.value : null,
    memberSlug: conductorStore.member.slug,
    conversationId: conductorStore.peerConversationId,

  }
  return commitment;
}
const getConversationActionText = (actionText: string) => {
  if (conductorStore.content?.conversationActionType === 'RoleBased') {
    return actionCommitmentStore.getCommitmentTextKey(actionText, conductorStore.member, conductorStore.content.conversationActionType);
  }
  return actionCommitmentStore.getCommitmentTextKey(actionText, conductorStore.member, null);
}
const getImpactArea = () => {
  return (whyImperativeBeforeNextConv.value.title == 'Other') ? otherText.value || '..' : whyImperativeBeforeNextConv.value.title;
}

const isActionSelected = () => {
  return (whyImperativeBeforeNextConv && selectedImpactArea) && (whyImperativeBeforeNextConv.value && selectedImpactArea.value)
}

const isOtherProvided = () => {
  return isActionSelected() && (whyImperativeBeforeNextConv.value.title == 'Other' ? otherText.value : true);
}

</script>
