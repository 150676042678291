<template>
  <div class="flex h-full flex-col">
    <div class="bg-white">
      <RadioGroup v-model="answers[q.id]" as="div" class="mb-2" v-for="q in questions" :key="q.label">
        <RadioGroupLabel class="break-words text-justify text-base font-semibold text-gray-700 lg:text-xl" as="div" role="none">{{ q.label }} </RadioGroupLabel>
        <div class="grid grid-cols-3 gap-3 sm:grid-cols-5">
          <RadioGroupOption as="template" v-for="(o, idx) in q.options" :key="idx" v-slot="{ checked }" :value="o.value">
            <div
              :class="[
                'cursor-pointer',
                checked ? '' : '  hover:bg-gray-100',
                'flex flex-col items-center justify-center rounded-md px-3 py-3 text-sm font-medium sm:flex-1',
              ]"
              :data-testid="`${q.id}-${o.value}`">
              <img
                :src="`https://assets.imperative.com/static.imperative.com/images/emojis/${checked ? '' : 'grayscale/'}emoji_${o.icon}${
                  checked ? '' : '_gray'
                }.png`"
                :alt="o.label"
                class="h-10 w-10 lg:h-14 lg:w-14"
                aria-hidden="true" />

              <span
                class="mt-2 w-full select-none break-words border border-transparent p-1 text-center"
                :class="checked ? 'border-primary font-semibold text-primary-700' : 'font-normal'">
                {{ o.label }}
              </span>
            </div>
          </RadioGroupOption>
        </div>
      </RadioGroup>

      <div class="mt-4 flex items-center justify-center">
        <base-button
          :disabled="!canContinue"
          class="text-normal rounded bg-primary-500 px-6 py-2 uppercase text-white hover:bg-primary-700"
          @click="advance"
          data-testid="preconvo-readytostart">
          {{ t('preConvoCheckin.readyToStart') }}
        </base-button>
      </div>
    </div>
    <base-exit-link class="mt-8" />
  </div>
</template>

<script setup lang="ts">
  import { reactive } from 'vue';
  import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue';
  import { useI18n } from 'vue-i18n';

  export interface Mood {
    id: string;
    label: string;
    options: MoodOption[];
  }

  export interface MoodOption {
    icon: string;
    label: string;
    value: number;
  }

  // props
  interface Props {
    content: any;
  }

  const props = withDefaults(defineProps<Props>(), {
    content: {},
  });

  // emits
  const emits = defineEmits<{
    (event: 'advance', payload: any): void;
  }>();

  const { t } = useI18n();

  const answers = reactive({
    relationship: '',
    impact: '',
    growth: '',
  }) as { [key: string]: string };

  const canContinue = computed(() => Number.isInteger(answers.relationship) && Number.isInteger(answers.impact) && Number.isInteger(answers.growth));

  const moods: Array<string> = ['frustrated', 'unhappy', 'meh', 'happy', 'pumped'];

  const questions: Array<Mood> = [
    {
      id: 'relationship',
      label: t('preConvoCheckin.relationship_title'),
      options: moods.map((mood, idx) => ({
        icon: mood,
        label: t('preConvoCheckin.' + mood),
        value: idx,
      })),
    },
    {
      id: 'impact',
      label: t('preConvoCheckin.impact_title'),
      options: moods.map((mood, idx) => ({
        icon: mood,
        label: t('preConvoCheckin.' + mood),
        value: idx,
      })),
    },
    {
      id: 'growth',
      label: t('preConvoCheckin.growth_title'),
      options: moods.map((mood, idx) => ({
        icon: mood,
        label: t('preConvoCheckin.' + mood),
        value: idx,
      })),
    },
  ];

  const advance = async () => {
    emits('advance', { ...answers });
  };
</script>
