<template>
  <div class="flex flex-col flex-1 px-4 mt-4 md:px-0">
    <div class="flex-1 space-y-4">
      <flow-panel :active-tab="active ? 0 : 1"></flow-panel>
      <div class="text-normal">{{ active ? t('selfSelect.introActive') : t('selfSelect.introInactive') }} </div>
      <div class="w-full px-4 py-8">
        <div class="w-full mx-auto">
          <RadioGroup v-model="selected" :disabled="!active">
            <RadioGroupLabel class="sr-only">Conversation Options</RadioGroupLabel>
            <div class="grid gap-4" :class="props.content.options.length > 4 ? 'grid-cols-2' : 'grid-cols-1 px-16'">
              <RadioGroupOption
                as="template"
                v-for="option in props.content.options"
                :key="option.value"
                :value="option.value"
                v-slot="{ active, checked }"
                :disabled="!active"
                :class="active ? 'cursor-pointer' : 'cursor-not-allowed'"
              >
                <div
                  :class="[
                    active
                      ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-primary-300 bg-primary-200'
                      : 'bg-primary-200',
                    checked ? 'bg-primary-500 text-white ' : '',
                  ]"
                  class="relative flex px-5 py-4 rounded-lg shadow-md focus:outline-none"
                >
                  <div class="flex items-center justify-between w-full">
                    <div class="flex items-center">
                      <div class="text-sm">
                        <RadioGroupLabel
                          as="p"
                          :class="checked ? 'text-white' : 'text-gray-900'"
                          class="font-medium"
                        >
                          {{ option.text }}
                        </RadioGroupLabel>
                        <RadioGroupDescription
                          as="span"
                          :class="checked ? 'text-sky-100' : 'text-gray-500'"
                          class="inline"
                        >
                          <span>{{ option.goals }}</span>
                        </RadioGroupDescription>
                      </div>
                    </div>
                    <div v-show="checked" class="text-white shrink-0">
                      <IconCircleCheck class="w-6 h-6" />
                    </div>
                  </div>
                </div>
              </RadioGroupOption>
            </div>
          </RadioGroup>
        </div>
      </div>
      <!-- BUTTONS -->
      <div class="flex-1 px-4 mt-4 text-right md:px-0">
        <base-button v-if="active" :disabled="!canContinue" uppercase @click="chooseConversation">
          {{ t('actionCheckIn.buttonText') }}
        </base-button>
      </div>
      <div class="z-20">
        <base-exit-link />
      </div>
    </div>
  </div>
</template>
  
<script setup lang="ts">
  import { PhaseProps, PhaseEmits, PhaseEmitName } from '@/Types';
  import { useConductorStore } from '@/stores/conductorStore';
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  
  const props = withDefaults(defineProps<PhaseProps>(), {});
  
  // emits
  const emits = defineEmits<PhaseEmits>();
  const { t } = useI18n();
  const active = computed(() => conductorStore.isActive);
  const selected = ref(null);
  const canContinue = computed(() => !!selected.value);
  
  // phases
  const conductorStore = useConductorStore();
  
  const chooseConversation = async () => {
    emits(PhaseEmitName.advance, selected.value );
  };
</script>
  